<div class="model-dialog">

    <div class="row">
        <div class="col-9">
            <h1>
                Order Details
            </h1>
        </div>
        <div class="col">
            <mat-form-field appearance="outline">
                <mat-select name="Allproduct" placeholder="Allproduct">
                    <mat-option [(value)]="All" (click)="productAll(All)"> All </mat-option>
                    <mat-option *ngFor="let group of finalvalue; let i = index;" [(value)]="group.title"
                    (click)="product(group.shortkey)">{{group.title}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-content class="mat-dialog-content-class">
        <div class="row">
            <div class="col-xs-3">
                <form [formGroup]="orderform" autocomplete="off">

                    <ng-container formArrayName="orderArray">
                        <mat-table [dataSource]="dataSource">


                            <ng-container matColumnDef="select" ngDefaultControl>
                                <mat-header-cell class="hd_Width" *matHeaderCellDef> Select </mat-header-cell>
                                <mat-cell class="hd_Width" *matCellDef="let element;" [formGroup]="element">
                                    <mat-checkbox [checked]="element.select" formControlName="select"> {{element.sn}}
                                    </mat-checkbox>

                                </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="name" ngDefaultControl>
                                <mat-header-cell class="order" *matHeaderCellDef> Product Name </mat-header-cell>
                                <mat-cell class="order" *matCellDef="let element;" [formGroup]="element">
                                    <mat-form-field class="readonly">
                                        <input matInput placeholder="Title" formControlName="name"
                                            required>{{element.name}}
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="group" ngDefaultControl>
                                <mat-header-cell class="align" *matHeaderCellDef> Group </mat-header-cell>
                                <mat-cell class="align" *matCellDef="let element;" [formGroup]="element">
                                    <mat-form-field>
                                        <input matInput placeholder="Title" formControlName="group"
                                            required>{{element.group}}
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="quantity" ngDefaultControl>
                                <mat-header-cell class="align" *matHeaderCellDef> Quantity </mat-header-cell>
                                <mat-cell *matCellDef="let element;" [formGroup]="element">
                                    <mat-form-field>
                                        <input matInput placeholder="Title" type="number" formControlName="quantity"
                                            required>{{element.quantity}}
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                        <!-- <mat-paginator #paginator
                        [pageSize]="5"
                        [pageSizeOptions]="[5, 100, 150]">
                </mat-paginator> -->
                    </ng-container>

                </form>
            </div>
        </div>
    </div>
    <div><mat-dialog-actions align="end">
        <button class="cancel" mat-button mat-dialog-close cdkFocusInitial (click)="cancel()">Cancel</button>
        <button class="save" mat-button [mat-dialog-close]="true" *ngIf="AppService.isSaveHide" (click)="saveProduct()" cdkFocusInitial>Save to
            Purchase</button>

    </mat-dialog-actions></div>
</div>