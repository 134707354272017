import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-orderinfo',
  templateUrl: './orderinfo.component.html',
  styleUrls: ['./orderinfo.component.scss']
})
export class OrderinfoComponent implements OnInit {
public orderData:any=[];
public billing: any = {};
public user: any = [];
  constructor(public service:AppService) { 
    this.service.show.next(true);
      }

  ngOnInit(): void {
   
    this.service.orderinfo.subscribe((x)=>{
      this.orderData=x;
      this.billing=this.orderData.billing;
 
 })     
  }

}
