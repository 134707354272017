<!-- <div  class="dialog"> -->
  <div class="dialogbox">
    <h4 class="h4">Please confirm...</h4>
    <!-- <button type="button" class="close" style="margin-left: 200px;"  (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="p">
    <p>Are you sure you want to verified <b>Bill number {{data.bill_no}} ?</b></p>
  </div>
  <!-- </div>
  <div mat-dialog-actions class="btngroup"> -->
  <div mat-dialog-actions class="button-group">
    <button mat-raised-button color="warn"(click)="onNoClick()">Cancel</button>
    <button mat-raised-button class="btn_PDF" (click)="verified()">Ok</button>
  </div>
<!-- </div> -->