import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from '../app.service';

@Component({
  selector: 'app-purchas',
  templateUrl: './purchas.component.html',
  styleUrls: ['./purchas.component.scss']
})

export class PurchasComponent implements OnInit {
  public dataSourceV1 = new MatTableDataSource();
  public dataSourceF1 = new MatTableDataSource();
  public dataSourceAll = new MatTableDataSource();
  public selection = new SelectionModel(true, []);
  public displayedColumns: string[] = ['select', 'name', 'total_quantity'];
  constructor(public appService: AppService, ) { 
    this.appService.show.next(true);
  }

  ngOnInit(): void {
    this.setDatasource();
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceF1.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceF1.filteredData.forEach(row => this.selection.select(row));
  }

  public setDatasource() {
    if (this.appService.purchas.length) {
      for (const data of this.appService.purchas) {
          for (const value of data) {
            if (value.group === 'F1') {
              this.dataSourceF1.data.push(value);
            } else if (value.group === 'V1') {
              this.dataSourceV1.data.push(value);
            } else {
              this.dataSourceAll.data.push(value);
            }
          }
      }
    }
  }

}
