import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from './app.service';
// import * as jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep, flatMap, forEach, includes, isEmpty, orderBy } from 'lodash';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { setTheme } from 'ngx-bootstrap/utils';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Router } from '@angular/router';
import { $ } from 'protractor';

let menuData = [
  {
    url: '/order',
    class: 'sidebar',
    name: 'Orders',
    active: ''
  },
  {
    url: '/costestimation',
    class: 'sidebar',
    name: 'Cost Estimation',
    active: ''
  },
  {
    url: '/purchasstatus',
    class: 'sidebar',
    name: 'Purchased Bills',
    active: ''
  }
]
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public title = 'kumariBasketWeb';
  public menuList: any = [];
  public isLoading = new BehaviorSubject<boolean>(false);
  @Output()
  dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
  public show = false;
  ngOnInit(): void {
    this.appService.menuList = menuData;
    this.appService.menuList.forEach((element: any,index) => {    
      if(window.location.pathname == element.url ){
        element.active='active'
      }
      else{
        element.active=''
      }
     });
  }
  public islogin: boolean;
  public navActive: boolean = false
  constructor(public appService: AppService,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    public router: Router) {

    this.appService.isLogin.subscribe((isLogin) => {
      appService.show = isLogin;
    });
    this.appService.loading.subscribe((data) => {
      this.isLoading = new BehaviorSubject<any>(data);
    });
    this.appService.login.subscribe((res) => {
      this.appService.islogin = true;
    });
    this.appService.show.subscribe((data) => {
      this.show = data;
    });

   
  }

  public logout() {

    localStorage.setItem('user', JSON.stringify([]));
    window.localStorage.clear();
    this.appService.isLogged = !this.appService.isLogged;
    this.appService.menuList.forEach((element: any,index) => {    
      if(index===0){
        element.active='active'
      }
      else{
        element.active=''
      }
     });
    this.router.navigate(['./login']);
    // this.appService.show.next(true);
  }
  clearStorages() {
    throw new Error('Method not implemented.');

  }

  public routeMenu(url,i) {
    this.appService.menuList.forEach((element: any,index) => {
       if(i === index){  
         element.active='active'
       }
       else{
         element.active=''
       }
      });
    this.router.navigate([`${url}`])
  }

}