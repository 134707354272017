
import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, Type } from '@angular/core';
import { async } from '@angular/core/testing';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { map, replace, forEach, filter, random, sum, parseInt } from 'lodash';
import { type } from 'os';
import { element } from 'protractor';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';
import { FileSaverService } from '../file-saver.service';
import { PurchasdetailsComponent } from '../purchasdetails/purchasdetails.component';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-purchasstatus',
  templateUrl: './purchasstatus.component.html',
  styleUrls: ['./purchasstatus.component.scss'],
  providers: [DatePipe]
})
export class PurchasstatusComponent implements OnInit {
  [x: string]: any;

  displayedColumns: string[] = ['select', 'purchasedate', 'purchasername', 'billno', 'status', 'action'];
  public dataSource: any = [];
  public purchaser_name1: any = [];
  public selection = new SelectionModel(true, []);
  public data: any = [];
  public getobj: any;
  public billcheck: any;
  public billnumber: any;
  public val: any = [];
  public newbillno: any;
  public objectbill: any;
  public fileName: any;
  public: any; userprofile
  public img: any;
  public photo: any;
  public amount1:any=[];
  public amount2:any=[];
  public url: 'This PC/downloads/archive.json';
  date = new FormControl(new Date().toISOString().slice(0, 10));
  serializedDate = new FormControl(new Date().toISOString().slice(0, 10));
  public currentDate = new Date().toISOString().slice(0, 10)
  dateValue: any = [];
  public verifiedData: any = []
  // currentDate: String=new Date().toLocaleString();c  
  matDialogRef: MatDialogRef<PurchasdetailsComponent>;
  public filteredData: any = []
  public datavalue: any = []
  public maxDate = new Date();
  constructor(public service: AppService,
    public router: Router,
    public http: HttpClient,
    public files: FileSaverService,
    private matDialog: MatDialog, private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private datepipe: DatePipe
  ) { 
    this.maxDate.setDate(this.maxDate.getDate())
  }
  ngOnInit(): void {
    this.getValue();
  }
  public getValue() {

    const date = {
      date: this.currentDate
    }
    this.http.post(environment.baseurl + 'user/getpurchasedet', date).subscribe((x) => {
      this.selection = new SelectionModel(true, []);
      this.datavalue = x;
      // this.data = this.datavalue
      this.setData();

    });
  }
  public applyFilter(e: MatDatepickerInputEvent<Date>) {

    let date: any = new DatePipe('en-US').transform(e.value, 'yyyy-MM-dd')
    const chooseDate = {
      date: date
    }
    this.http.post(environment.baseurl + 'user/applyFilter', chooseDate).subscribe((x) => {
      this.selection = new SelectionModel(true, []);
      this.datavalue= x;
      this.setData();
    });
  }
  public setData() {
    const newarray = this.datavalue.map(element => ({
      billing_no: element["billing_no"],
      purchasedate: element["purchasedate"], status: element["status"],
      purchase_status: element["purchase_status"], purchasername: element["purchasername"],
      user_id: element["user_id"],
    }));
    const uniqueValuesSet = new Set();
    this.data = newarray.filter((obj) => {
      const isPresentInSet = uniqueValuesSet.has(obj.billing_no);
      uniqueValuesSet.add(obj.billing_no);
      this.service.getbill = obj;
      return !isPresentInSet;

    })
    this.dataSource=this.data
  }


  view(data: any) {
    let billing_no = localStorage.setItem("billing_no", data.billing_no)

    this.matDialogRef = this.matDialog.open(PurchasdetailsComponent, {
      data,
    });
    this.matDialogRef.afterClosed().subscribe(() => {
      this.getValue();
    });
  }
  download(index) {
    const file = {
      bill: this.data[index].billing_no
    }
    this.http.post(environment.baseurl + 'user/getfile', file).subscribe(async (x: any) => {
        this.userprofile = x;
        this.userprofile.forEach(async x => {
          this.photo = x ? this.service.getImagePath(x):'';
          const base64Response = await fetch(this.photo);
          const blob = await base64Response.blob();
          saveAs(blob,this.getRandomInt()+'.png')
         });
    });


  }

  Update() {
    const vv: any = this.data.filter((element: any) => (this.date.value != element.purchasedate || element.status != "Verified"));

    const bb: any = this.data.filter((element: any) => (this.date.value == element.purchasedate && element.status === "Verified"));
    if (this.selection.selected.length > 0) {

      if (vv.length == 0 && this.selection.selected.length == this.data.length) {
        let payLoad = {
          currentdate: this.currentDate,
          Status: 'Completed',
          purchasedate: this.date.value,
        }
        
        this.http.post(environment.baseurl + 'user/mailtrigger', payLoad).subscribe((res) => {
          if(res){
            this.toastr.success('Completed sucessfully');
          }
          this.getValue();
        });

      }
      else {
        this.toastr.warning('Choose all Verified bills')
      }
    } else {
      this.toastr.warning('Choose atleast one value')
    }
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.forEach(row => this.selection.select(row));
  }

  public isAllSelected() { //Select mulitiple values
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  getRandomInt() {
    return Math.floor(100000 + Math.random() * 900000);
  }
}

