
  
<div class="pad" >
    <span class="material-icons" style="margin-top: 10px;" (click)="goback()">arrow_back </span>
    <!-- <div class="mat-elevation-z8"> -->
        <!-- <div class="dashboard-card "> -->
          <div class="btn-group-item1">
         <h2 style="    margin-top: 25px;margin-left: 20px;"> Customer Details</h2>
          </div>
            <div class="row">
                <div class="column">
                    <table>
                            <tr>
                                <mat-card>
                                    <mat-card-title>Customer Name:</mat-card-title>
                                    <mat-card>{{billing.first_name}} {{billing.last_name}}</mat-card>
                                </mat-card>
                            </tr>
                            <tr>
                                <mat-card>
                                    <mat-card-title>Mobile number:</mat-card-title>
                                    <mat-card>{{billing.phone}}</mat-card>
                                </mat-card>
                            </tr>
                            <tr>
                                <mat-card>
                                    <mat-card-title>City:</mat-card-title>
                                    <mat-card>{{billing.city}}</mat-card>
                                </mat-card>
                                
                            </tr>
                            <tr>
                                <mat-card>
                                    <mat-card-title>Address:</mat-card-title>
                                    <mat-card>{{billing.address_1}}</mat-card>
                                </mat-card>
                            </tr>
                    </table>
                </div>

                <div class="column">
                    <table>
                            <tr>
                                <mat-card>
                                    <mat-card-title>User name:</mat-card-title>
                                    <mat-card>{{user.username}}</mat-card>
                                </mat-card>
                            </tr>
                            <tr>
                                <mat-card>
                                    <mat-card-title>Email:</mat-card-title>
                                    <mat-card>{{billing.email}}</mat-card>
                                </mat-card>
                            </tr>
                            <tr>
                                <mat-card>
                                    <mat-card-title>State:</mat-card-title>
                                    <mat-card>{{billing.state}}</mat-card>
                                </mat-card>
                            </tr>
                    </table>
                </div>

                <div class="column">
                    <table>                        
                            <tr>
                                <mat-card>
                                    <mat-card-title>Customer Id:</mat-card-title>
                                    <mat-card>{{user.id}}</mat-card>
                                </mat-card>
                            </tr> 
                            <tr>
                                <mat-card>
                                    <mat-card-title>Postcode:</mat-card-title>
                                    <mat-card>{{billing.postcode}}</mat-card>
                                </mat-card>
                            </tr>
                             <tr>
                                <mat-card>
                                    <mat-card-title>Country:</mat-card-title>
                                    <mat-card>{{billing.country}}  </mat-card>
                                </mat-card>
                            </tr>                              
                    </table>
                    
                </div>
            </div>
                <div class="pad1">
                    <div class="mat-elevation-z8">
                       <div >
                        <table mat-table  [dataSource]="dataSource">
                
                            <ng-container matColumnDef="sn" style="padding-left: 0px;margin-left: 0px;">
                                <th mat-header-cell class="header_align" *matHeaderCellDef> SN </th>
                                <td mat-cell class="header_align" *matCellDef="let element;let i=index;"> {{i+1}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="id">
                                <th mat-header-cell class="back_color" *matHeaderCellDef> Order Id </th>
                                <td mat-cell class="cursor back_color" *matCellDef="let element" (click)="openpopup(element)">
                                    {{element.id}} </td>
                            </ng-container>
                
                
                            <ng-container matColumnDef="postcode">
                                <th mat-header-cell class="header_align" *matHeaderCellDef> Pin code </th>
                                <td mat-cell class="header_align" *matCellDef="let element"> {{element.billing.postcode}} </td>
                            </ng-container>
                
                
                            <ng-container matColumnDef="delivery_date" >
                                <th mat-header-cell *matHeaderCellDef class="header_align"> Created date & time </th>
                                <td mat-cell class="header_align" *matCellDef="let element"> {{element.date_created|date:'dd-MM-yyyy H:mm'}} </td>
                            </ng-container>
                
                
                            <ng-container matColumnDef="name">
                                <th mat-header-cell class="header_align" *matHeaderCellDef> Customer Name </th>
                                <td mat-cell class="header_align" *matCellDef="let element">
                                    {{element.billing.first_name}} {{element.billing.last_name}}</td>
                            </ng-container>
                
                
                            <ng-container matColumnDef="address">
                                <th mat-header-cell class="header_align" *matHeaderCellDef> Address </th>
                                <td class="header_align" style="width: 281px;
                          word-break: break-word;" mat-cell *matCellDef="let element"> {{element.billing.address_1}} </td>
                            </ng-container>
                            <ng-container matColumnDef="payment_method">
                                <th mat-header-cell class="header_align" *matHeaderCellDef> Payment Method </th>
                                <td mat-cell class="header_align" *matCellDef="let element"> {{element.payment_method}} </td>
                            </ng-container>
                            <ng-container matColumnDef="total">
                                <th mat-header-cell class="header_align" *matHeaderCellDef> Total </th>
                                <td mat-cell class="header_align" *matCellDef="let element"> {{element.total}} </td>
                            </ng-container>
                         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator #paginator [pageSize]="5" 
                        [pageSizeOptions]="[5, 10, 20, 50]" 
                        showFirstLastButtons>

                        </mat-paginator>
                       </div>
                    </div>
                </div>
            </div>
    <!-- <tr > -->
        


