<mat-toolbar class="tool_back">
    <img class="tool_img" src="/assets/images/KB-logo.png" alt="kumaribasket">
  </mat-toolbar>
<loading *ngIf="isLoading.value"></loading>
<div>
    <div class='container-fluid'>
        <div class='pad'>
            <div class='btn-group'>
                <div class='btn-group-item1'>
                    <h2>Customer Details</h2>
                </div>
                <div class='btn-group-item2'>
                    <button mat-flat-button class='btn_PDF' routerLink='/'>Back to Order</button>
                </div>
                <div class='btn-group-item2'>
                    <button mat-flat-button class='btn_PDF' (click)="downloadAsCSV()">Download As CSV</button>
                </div>
                <div class='btn-group-item4'>
                    <mat-form-field appearance='outline'>
                        <mat-label>Search Customer</mat-label>
                        <input type='text' (keyup)="applyFilter($event.target.value)" matInput
                            placeholder='Enter the customer'>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class='pad'>
            <div class='mat-elevation-z8'>
                <table mat-table [dataSource]='dataSource'>

                    <!-- <ng-container matColumnDef='select'>
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)='$event ? masterToggle() : null'
                                [checked]='selection.hasValue() && isAllSelected()'
                                [indeterminate]='selection.hasValue() && !isAllSelected()'>
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef='let row'>
                            <mat-checkbox (click)='$event.stopPropagation()'
                                (change)='$event ? selection.toggle(row) : null' [checked]='selection.isSelected(row)'>
                            </mat-checkbox>
                        </td>
                    </ng-container> -->
                    <!-- <ng-container matColumnDef='sn'>
                        <th mat-header-cell *matHeaderCellDef> SN </th>
                        <td mat-cell *matCellDef='let element'> {{element.sn}} </td>
                    </ng-container> -->

                    <ng-container matColumnDef='id'>
                        <th mat-header-cell *matHeaderCellDef> Customer Id </th>
                        <td mat-cell *matCellDef='let element'> {{element.id}} </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef='postcode'>
                        <th mat-header-cell *matHeaderCellDef> Pin code </th>
                        <td mat-cell *matCellDef='let element'> {{element.postcode}} </td>
                    </ng-container> -->

                    <ng-container matColumnDef='first_name'>
                        <th mat-header-cell *matHeaderCellDef> Customer Name </th>
                        <td mat-cell *matCellDef='let element'> {{element.first_name}} {{element.last_name}} </td>
                    </ng-container>

                    <ng-container matColumnDef='orderCount'>
                        <th mat-header-cell *matHeaderCellDef> Order Count </th>
                        <td style='width: 281px;
                                   word-break: break-word;' mat-cell *matCellDef='let element'> {{element.orderCount}} </td>
                    </ng-container>

                    <ng-container matColumnDef='email'>
                        <th mat-header-cell *matHeaderCellDef> Email </th>
                        <td mat-cell *matCellDef='let element'> {{element.email}} </td>
                    </ng-container>

                    <ng-container matColumnDef='postcode'>
                        <th mat-header-cell *matHeaderCellDef> Postal Code </th>
                        <td mat-cell *matCellDef='let element'> {{element.postcode}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
                    <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
                </table>

                <mat-paginator [pageSizeOptions]='[100,200,300,400]' showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>