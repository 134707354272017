

<mat-toolbar class="tool_back" *ngIf="islogin">

  <img class="tool_img" src="/assets/images/KB-logo.png" alt="kumaribasket">
</mat-toolbar>
<!--Main Navigation-->
<header  *ngIf="appService.show" >
  <!-- Sidebar -->
  <nav 
       id="sidebarMenu"
       class="collapse d-lg-block sidebar collapse bg-white"
       >
       <div class="sidebar">
    <div class="menu-wrapper">
      <div class="list-group list-group-flush mx-3 mt-4 ss">
        <ul *ngFor="let menu of appService.menuList;let i=index">
          <li>
            <a [routerLink]="[menu.url]" class="{{menu.active}}"(click)="routeMenu(menu.url,i)">
              <!-- <span></span> -->
              {{menu.name}}
            </a>
          </li>
        </ul>
        <!-- <a 
        (click)="routeMenu('/order')"
           class="list-group-item list-group-item-action py-2 ripple"
           ><i class="fas fa-chart-line fa-fw me-3"></i
          ><span>Order</span></a
          >
        <a
        (click)="routeMenu('/costestimation')"
           class="list-group-item list-group-item-action py-2 ripple"
           >
          <i class="fas fa-chart-pie fa-fw me-3"></i><span>Cost Estimation</span>
        </a>
        <a (click)="routeMenu('/purchasstatus')"
           
           class="list-group-item list-group-item-action py-2 ripple"
           ><i class="fas fa-chart-bar fa-fw me-3"></i><span>Purchased Bills</span></a
          > -->
      </div>
    </div>
       </div>
  </nav>
  <!-- Sidebar -->

  <!-- Navbar -->
  <nav
       id="main-navbar"
       class="navbar navbar-expand-lg navbar-light bg-white fixed-top"
       >
    <!-- Container wrapper -->
    <div class="container-fluid">
      <!-- Toggle button -->
      <!-- <button
              class="navbar-toggler"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#sidebarMenu"
              aria-controls="sidebarMenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
              >
        <i class="fas fa-bars"></i>
      </button> -->

      <!-- Brand -->
      <a class="navbar-brand" [routerLink]="['/order']">
        <img
             src="\assets\images\KB-logo.png"
             height="40"
             alt=""
             loading="lazy"
             />
      </a>

      <ul class="navbar-nav ms-auto d-flex flex-row">

        <li class="nav-item dropdown" style="min-height: 48px;min-width: 0px;padding-bottom: 8px;">
          <a mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" class="pr">
            
            <img src="https://mdbootstrap.com/img/Photos/Avatars/img (31).jpg" class="rounded-circle profile-image" height="22"
            alt="Black and White Portrait of a Man" loading="lazy" />

            <mat-icon class="arrow">expand_more</mat-icon>

            <mat-menu #menu="matMenu">
              <button mat-menu-item style="padding-left: 10px; text-align: center;margin: 0px;border-radius: 4px;font-size: 17px;"
               (click)="logout()" class="menu-button">
                <span>Logout</span>
              </button>
            </mat-menu>

          </a>
          
        </li>
      </ul>
    </div>
    <!-- Container wrapper -->
  </nav>
  <!-- Navbar -->
</header>
<!--Main Navigation-->

<!--Main layout-->
  <main>
    <div class="pt-4">
      <router-outlet></router-outlet>
    </div>
  </main>
<!-- <div *ngIf="!show"> -->
  <!-- <router-outlet></router-outlet> -->
<!-- </div> -->
