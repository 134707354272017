import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { config } from 'process';
import { AuthGuard } from './auth.guard';
import { CostestimationComponent } from './costestimation/costestimation.component';
import { CustomerComponent } from './customer/customer.component';
import { CustomerdetailsComponent } from './customerdetails/customerdetails.component';
import { DeliverydetailsComponent } from './deliverydetails/deliverydetails.component';
import { DialogboxComponent } from './dialogbox/dialogbox.component';
import { LoginComponent } from './login/login.component';
import { OrderComponent } from './order/order.component';
import { OrderinfoComponent } from './orderinfo/orderinfo.component';
import { PurchasComponent } from './purchas/purchas.component';
import { PurchasdetailsComponent } from './purchasdetails/purchasdetails.component';
import { PurchasstatusComponent } from './purchasstatus/purchasstatus.component';

const routes: Routes = [
  { path: '',component: LoginComponent },
  { path: 'login', component: LoginComponent},
  { path: 'order',canActivate:[AuthGuard],component: OrderComponent },
  { path: 'customer',canActivate:[AuthGuard], component: CustomerComponent },
  { path: 'customerdetails',canActivate:[AuthGuard], component: CustomerdetailsComponent },
  { path: 'purchas',canActivate:[AuthGuard], component: PurchasComponent },
  { path: 'purchasdetails',canActivate:[AuthGuard], component: PurchasdetailsComponent },
  { path: 'purchasstatus', canActivate:[AuthGuard],component: PurchasstatusComponent },
  { path: 'deliverydetails',canActivate:[AuthGuard],component: DeliverydetailsComponent },
  { path: 'costestimation',canActivate:[AuthGuard],component: CostestimationComponent},
  { path: 'dialogbox',canActivate:[AuthGuard],component: DialogboxComponent },
  { path: 'orderinfo',canActivate:[AuthGuard],component: OrderinfoComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
