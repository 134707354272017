import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { orderBy } from 'lodash';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { AppService } from '../app.service';
// import { Angular2Csv } from 'angular2-csv';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
  public count = 50;
  public customer: any[] = [];
  public email: any[] = [];
  public dataSource = new MatTableDataSource();
  public displayedColumns: string[] = ['id', 'first_name', 'orderCount', 'email', 'postcode'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public isLoading = new BehaviorSubject<boolean>(false);
  subscription: Subscription;
  public allCustomers: any[] = [];
  constructor(public appService: AppService) {
    this.appService.isMoreCustomers.subscribe((data) => {
      if (data) {
        this.getAllCustomer(data + 1);
      }
    });
    this.appService.isMoreOrders.subscribe((data1) => {
      
      if (data1) {
        this.getAllOrders(data1 + 1);
      }
    });
    this.appService.loading.subscribe(data => {
      this.isLoading = new BehaviorSubject<any>(data);
    });
  }

  ngOnInit(): void {
    this.appService.orders = [];
    // this.getAllOrders(1);
    this.getAllCustomer(1);
    this.getAllOrders1();
  }
  public getAllCustomer(i) {
    const customerList: any = [];
    for (let i = 1; i < 30; i++) {
      customerList.push(this.appService.getWoo(this.appService.apiurl.kumari.customer, ['100', `${i}`]));
    }
    this.appService.loading.next(true);
    forkJoin(customerList).subscribe((data: any) => {
      if (data.length) {
        // this.appService.isMoreCustomers.next(i);
        for (const item of data) {
          this.customer.push(...item);
        }
      } else {
        // let d = '';
        // for (const item of this.customer) {
        //   this.email.push(item.email);
        //   d = this.email.join(',');
        // }
        // this.mappingCustomerWithOrder(1);
      }
    });
  }
  // ngOnDestroy() {
  //   this.subscription.unsubscribe()
  // }

  public getAllOrders(i) {
    this.appService.getWoo(this.appService.apiurl.kumari.getallorders, ['100', `${i}`]).subscribe((data: any) => {
      if (data.length) {
        this.appService.orders.push(...data);
        this.appService.isMoreOrders.next(i);
      
      } else {
        this.mappingCustomerWithOrderCount();
      }
    });
  }

  public getAllOrders1() {
    const orderList: any = [];
    for (let i = 1; i < 20; i++) {
      orderList.push(this.appService.getWoo(this.appService.apiurl.kumari.getallorders, ['100', `${i}`]));
    }
    forkJoin(orderList).subscribe((data: any) => {
      if (data.length) {
        for (const item of data) {
          this.appService.orders.push(...item);
        }
        this.mappingCustomerWithOrderCount();
      } else {
      }
    });
  }

  public mappingCustomerWithOrderCount() {
    let cust: any = [];
    for (const c of this.customer) {
      cust = this.appService.orders.filter((x) => x.customer_id === c.id);
      c.orderCount = cust.length > 0 ? cust.length : 0;
      c.postcode = c.billing.postcode;
      c.phone = c.billing.phone;
      c.address = `${c.billing.address_1},${c.billing.address_2},${c.billing.phone}`;
    }
    this.customer = orderBy(this.customer, ['orderCount'], ['desc']);
    this.dataSource.data = this.customer;
    let count = 0;
    this.allCustomers = this.customer.map((c) => {
      count++;
      return {
        SNo: count,
        CustomerId: c.id,
        FirstName: c.first_name,
        OrderCount: c.orderCount,
        Email: c.email,
        PostCode: c.postcode,
        PhoneNumber: c.phone
      };
    }
    );
    this.appService.loading.next(false);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  downloadAsCSV() {
    const data = this.allCustomers;
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, 'KB_Customers.csv');
  }
}
