import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppService } from '../app.service';

@Component({
  selector: 'loading',
  template: `		<div id="pause" class="d-flex align-items-center justify-content-center">
							<div class="spinner" id="pause">
	<div class="bounce1"></div>
	<div class="bounce2"></div>
	<div class="bounce3"></div>
</div>
								</div>`,
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  // BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(public appService: AppService) { }

  ngOnInit(): void {
  }

}
