<div class="kb">
    <div class="row">
        <div class="col-md-4">

        </div>
        <div class="col-md-4">
            <div class="kb-card">
                <div>
                    <img src="\assets\images\KB-logo.png" alt="">
                </div>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form">
                    <div class="form-value">
                    <input formControlName="username" [ngClass]="{ 'is-invalid': submitted && loginvalid.username.errors }"
                        class="input" type="text" placeholder="Enter Username" />
                    <div *ngIf="submitted && loginvalid.username.errors">
                        <span style="color: #ef6260;" *ngIf="loginvalid.username.errors.required">* Please Enter
                            Username</span>
                        <span style="color: #ef6260;" *ngIf="loginvalid.username.errors.minlength">* Enter Valid
                            Username</span>
                    </div>
                </div>
                <div>
                    <div class="form-value">
                    <input formControlName="password"  [ngClass]="{ 'is-invalid': submitted && loginvalid.password.errors }"
                        class="input" type="password" placeholder="Enter Password">
                    <div *ngIf="submitted && loginvalid.password.errors">
                        <div style="color: #ef6260;" *ngIf="loginvalid.password.errors.required">* Please Enter Password
                        </div>
                        <!-- <div style="color: white;" *ngIf="loginvalid.password.errors.minlength">* Enter Valid Password</div> -->
                    </div>
                </div>
                </div>
                <div class="btn-cnt">
                    <button class="button" style="margin-top: 20px;" color="success" type="submit">login</button>
                </div>
                </form>
            </div>
        </div>
        <div class="col-md-4">
            
        </div>
    </div>
</div>