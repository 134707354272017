import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { unescapeIdentifier } from '@angular/compiler';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { group } from 'console';
import * as FileSaver from 'file-saver';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AppService } from '../app.service';

@Component({
  selector: 'app-orderdetails',
  templateUrl: './orderdetails.component.html',
  styleUrls: ['./orderdetails.component.scss']
})
export class OrderdetailsComponent implements OnInit {
  dataSource = new BehaviorSubject([]);
  public orderform = new FormGroup({
    orderArray: new FormArray([
      new FormGroup({
        select: new FormControl(''),
        // disabled:new FormControl(false),
        name: new FormControl(''),
        group: new FormControl(''),
        quantity: new FormControl(''),
        price: new FormControl(''),
        // isDisable: new FormControl(false)

      })
    ])
  });
  public productarray:any[] = [];
  public pg: any = [];
  public checkedarray: any = [];
  public getMasterCategory: any = [];
  public filteredArr: any = [];
  public pgroup: any = [];
  public datas: any;
  public val: any;
  public isProduct: boolean = false;
  public disabledproducts: any;
  public suborderid: any = {};
  public orderArray = this.orderform.get('orderArray') as FormArray;
  public filterArray =  this.orderform.get('orderArray') as FormArray;
  public orderList: any[] = [];
  public newarray: any;
  public displayedColumns = ['select', 'name', 'group', 'quantity'];
  // public dataSource = new MatTableDataSource(this.orderArray.controls);
  public selection = new SelectionModel(true, []);
  public newvalue: any;
  public filterdata: any;
  public alreadyexist: boolean = false;
  public suborderids = [];
  // public productarray: any[] = [];
  user: any[];
  finalvalue: any[] = [];
public filterval:any =[];
  newProdArray: any;
  All={}
  public date = new Date().toISOString().slice(0, 10)
  constructor(private _formBuilder: FormBuilder,
    public AppService: AppService,
    public router: Router,
    public http: HttpClient,
    private toastr: ToastrService,public cdef : ChangeDetectorRef,

    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.AppService.orderview.subscribe((data) => {
      this.suborderid = data;
      console.log(this.suborderid);
      
      for (let index = 0; index < this.suborderid.line_items.length; index++) {
        this.suborderids.push(this.suborderid.line_items[index].name);
      }
    })

  }


  ngOnInit(): void {
    if (this.data.status != 'processing' && this.data.status != 'Not-verify') {
      this.AppService.isSaveHide = false;
    } else {
      this.AppService.isSaveHide = true;
    }
    this.data.line_items.forEach((ele, i) => {
      if (this.data.line_items.length !== i + 1) {
        this.orderArray.push(new FormGroup({
          select: new FormControl(false),
          // disabled: new FormControl(false),
          name: new FormControl(''),
          group: new FormControl(''),
          quantity: new FormControl(''),
          price: new FormControl(''),

        }));
      }
    })
    const disval = {
      orderid: this.data.id,
    };


    this.http.post(this.AppService.apiurl.node.productdisabled, disval).subscribe((res: any) => {
      this.disabledproducts = res;
      this.productarray = cloneDeep(res);

      this.data.line_items.forEach((ele) => {
        const value = this.disabledproducts.find((x) => x.productname === ele.name);
        ele.quantity = value && value.quantity ? value.quantity : ele.quantity;
      });

      this.data.line_items.forEach((ele, i) => {

        const vv = this.disabledproducts.find((x) => x.productname == ele.name && x.purchase_status !== "draft");
        const draftProduct = this.disabledproducts.find((x) => x.productname == ele.name && x.purchase_status == "draft");
        const storeProduct = this.disabledproducts.find((x) => x.productname == ele.name   && x.isStore == false);

        if (this.data.status != 'processing' && this.data.status != 'Not-verify' && storeProduct?.isStore == false ) {
          
          this.orderArray.controls[i].patchValue({
            select: true,
            name: ele.name,
            group: ele.sku.substring(0, 2),

            quantity: ele.quantity,
            price: ele.total,
  
          });
          this.orderArray.controls[i].disable();
        }
        else {
        if (vv?.productname == ele.name && !draftProduct) {
          this.orderArray.controls[i].patchValue({
            select: false,
            // disabled:true,
            name: ele.name,
            group: ele.sku.substring(0, 2),
            quantity: ele.quantity,
            price: ele.total,

          });
          this.orderArray.controls[i].disable();
          

        }
        
        else if (draftProduct) {
          this.orderArray.controls[i].patchValue({
            select: true,
            // disabled:true,
            name: ele.name,
            group: ele.sku.substring(0, 2),
            quantity: ele.quantity,
            price: ele.total,

          });
          // this.orderArray.controls[i].disable();
        }
        else {
          this.orderArray.controls[i].patchValue({
            select: false,
            // disabled:false,
            name: ele.name,
            group: ele.sku.substring(0, 2),
            quantity: ele.quantity,
            price: ele.total,

          });


        }
      }
      });
      this.getmastercategory();
      

    })

   
  }

public arrayValue(){
  this.orderArray.clear();
  this.filterval.forEach((ele, i) => {
    if (this.filterval.length > i) {
      this.orderArray.push(new FormGroup({
        select: new FormControl(false),
        name: new FormControl(''),
        group: new FormControl(''),
        quantity: new FormControl(''),
        price: new FormControl(''),
      }));
    }
  })
    this.filterval.forEach((ele, i) => {
      const vv = this.disabledproducts.find((x) => x.productname == ele.name && x.purchase_status !== "draft");
      const draftProduct = this.disabledproducts.find((x) => x.productname == ele.name && x.purchase_status == "draft");
      const storeProduct = this.disabledproducts.find((x) => x.productname == ele.name   && x.isStore == false);

      if (this.data.status != 'processing' && this.data.status != 'Not-verify' && storeProduct?.isStore == false) {
        this.orderArray.controls[i].patchValue({
          select: true,
          name: ele.name,
          group: ele.sku.substring(0, 2),
          quantity: ele.quantity,
          price: ele.total,

        });
        this.orderArray.controls[i].disable();
      }
      else {
        if (vv?.productname == ele.name && !draftProduct) {
          this.orderArray.controls[i].patchValue({
           select: false,
           name: ele.name,
           group: ele.sku.substring(0, 2),
           quantity: ele.quantity,
           price: ele.total,
 
         });
         this.orderArray.controls[i].disable();
 
       } else if (draftProduct) {
         this.orderArray.controls[i].patchValue({
           select: true,
           name: ele.name,
           group: ele.sku.substring(0, 2),
           quantity: ele.quantity,
           price: ele.total,
         });
 
       }
 
       
       else {
         this.orderArray.controls[i].patchValue({
           select: false,
           name: ele.name,
           group: ele.sku.substring(0, 2),
           quantity: ele.quantity,
           price: ele.total,
         });
 
 
       }
      }
     
    });
    this.orderArray.controls.forEach((order:any,i) =>{
      if(order.controls.group.value=== null){
        this.orderArray.removeAt(i);
      }
    })
  this.dataSource.next([]);
    this.dataSource.next(this.orderArray.controls); 
}

  public getmastercategory() {
    this.http.get(environment.baseurl + 'user/getmaster').subscribe((x) => {
      this.getMasterCategory = x;
      
      this.pg = this.orderArray.value
      let result = [];
      const newProduct = this.pg.map(product => ({ key: product["group"] }))
      const uniqueValuesSet = new Set();
      this.filteredArr = newProduct.filter((obj) => {
        const isPresentInSet = uniqueValuesSet.has(obj.key);
        uniqueValuesSet.add(obj.key);
        return !isPresentInSet;
      });
      let finalvalue = [];
      result = this.pg;
      for (var i = 0; i < result.length; i++) {
        result[i].isChecked = false;
      }
      this.filteredArr.forEach(object => {
        let objects = {
          shortkey:object.key,
          title: object.key,
          check: false,
          intermidiate: false,
          child: result.filter(ele => ele.group === object.key)
        }
        this.finalvalue.push(objects);
      });
      for (let index = 0; index < this.finalvalue.length; index++) {
        for (let j = 0; j < this.getMasterCategory.length; j++) {
          if (this.finalvalue[index].title === this.getMasterCategory[j].key)
            this.finalvalue[index].title = this.getMasterCategory[j].value
        }
      }
      this.dataSource.next(this.orderArray.controls);
    });
    
  }
 
  product(Value){
    if(Value){
      let array = this.data.line_items
      this.filterval = array.filter((x) => x.sku.substring(0, 2) == Value);
     this.arrayValue()
     }
    
  }
  productAll(allvalue){
   if(allvalue="All"){
      this.filterval=this.data.line_items
     this.arrayValue();
    }
  }
  saveProduct() {
    this.AppService.getId = this.data.id;
    this.orderList = this.orderform.get('orderArray').value.filter((x) => x.select === true);
    console.log('this.orderList',this.orderList);
    this.data.line_items.forEach(x => {
      this.orderList.map(y => {
          if (x.name === y.name) {
            y.price= x.price * y.quantity 
          }
      }
      )
    });
    let deletelist = []
    for (let product of this.productarray) {
      let data = this.orderList.find(list => list.name == product.productname && list.select === true);
      if (!data) {
        deletelist.push(product);
      }
    }
    
    // if(this.orderList.length>0){
      this.datas = {
        product: this.orderList,
        productToRemove: deletelist,
        orderid: this.data.id,
        date: this.date,
        time: this.data.delivery_time,
        status: 'Not-verify'
      };
      this.http.post(this.AppService.apiurl.node.orderDetails, this.datas).subscribe((res) => {
        if (res==='success') {
          this.toastr.success('Value added successfully' , 'Success');
        }else if(res ==='failed'){
          this.toastr.warning('No products selected' , 'Info');
        } else{
          this.toastr.error('Need to check the value', 'Error');
        }
      });
    // }  
  }
  cancel() {
    this.router.navigate(['order'])
  }

  public resetForm() {
    this.orderArray.value.forEach((ele, i) => {
      this.orderArray.removeAt(i);
    });
  }
}

function index(index: any) {
  throw new Error('Function not implemented.');
}

