import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public username: any;
  public password: any;
  public usertype:any;
  public user: any = [];
  loginForm: FormGroup;
  submitted = false;
  public isLoading = new BehaviorSubject<boolean>(false);

  constructor( private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public service:AppService,
    private toastr: ToastrService,) {
      // this.service.show.next(false);
    this.service.isLogin.next(false);
  }
  
    public get loginvalid() { return this.loginForm.controls; }
  ngOnInit(): void {
 
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      password: ['', Validators.compose([Validators.required])],

    });
   
  }
  login() {
    this.username = this.loginForm.value.username;
    this.password = this.loginForm.value.password;
    this.usertype ='StoreManager';
    const payload = {
      username: this.username,
      password: this.password,
      usertype:this.usertype
    }
    this.http.post(environment.baseurl+ 'user/loginweb', payload).subscribe((res: any) => {
      if (res.status === "success") {
        this.user = res.user;
        this.userlog();
        this.router.navigate(['order']);
        this.service.login.next(res);
      } else {
        this.toastr.warning('InValid User', 'Error');
      }
      // this.user=res;
     });
     
  }
  public setUser(user, route) {
    localStorage.setItem('user', JSON.stringify(user));
      if (user.usertype === 'StoreManager') {
      this.service.loading.subscribe(data=>{
        this.isLoading = new BehaviorSubject<any>(data);
      })
    }
    this.service.menuList.forEach((element: any,index) => {
      if(index===0){  
        element.active='active'
      }
      else{
        element.active=''
      }
     });
    this.router.navigate([route]);
  }
  public userlog() {
  
    this.service.isLogged = !this.service.isLogged;
    if (this.user[0].usertype === 'StoreManager') {
      this.setUser(this.user[0], 'order');
    }
  }
  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.login();
    }
  }
}
