import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { MatFormFieldControl, MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CustomerComponent } from './customer/customer.component';
import { OrderComponent } from './order/order.component';
import { Angular2CsvModule } from 'angular2-csv';
import { PurchasComponent } from './purchas/purchas.component';
import { OrderdetailsComponent } from './orderdetails/orderdetails.component';
import { CustomerdetailsComponent } from './customerdetails/customerdetails.component';
import { DeliverydetailsComponent } from './deliverydetails/deliverydetails.component';
import { PurchasstatusComponent } from './purchasstatus/purchasstatus.component';
import { PurchasdetailsComponent } from './purchasdetails/purchasdetails.component';
import { CommonModule, DatePipe } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {MatTooltipModule} from '@angular/material/tooltip';

import { MatInputModule } from '@angular/material/input';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';
import { CostestimationComponent } from './costestimation/costestimation.component';
import { DialogboxComponent } from './dialogbox/dialogbox.component';
import { OrderinfoComponent } from './orderinfo/orderinfo.component';
import { filter } from 'lodash';
@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    CustomerComponent,
    OrderComponent,
    PurchasComponent,
    OrderdetailsComponent,
    CustomerdetailsComponent,
    DeliverydetailsComponent,
    PurchasstatusComponent,
    PurchasdetailsComponent,
    LoginComponent,
    CostestimationComponent,
    DialogboxComponent,
    OrderinfoComponent
  ],
  imports: [
  
    BrowserModule,
    MatFormFieldModule,
    MatInputModule, 
    MaterialModule,
    AppRoutingModule,
    // MatMenuModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    MaterialModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    Angular2CsvModule,
    CommonModule,
    ToastrModule,
    MatTooltipModule

  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
