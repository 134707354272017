<div class="dialog-box">
<div class="model-dialog" style="margin-top: 50px;height: 600px;width: 75   0px;  
overflow-y : scroll;">
    <!-- <div class="mat-elevation-z8"> -->
        <!-- <div class="dashboard"> -->
            <div>
                <span style="cursor: pointer; float: right;
                color: rgba(5, 5, 5, 0.966);margin-top: 3px;" ><mat-icon matTooltip="close"  (click)="back()"  [matTooltipPosition]="'right'">close</mat-icon></span>
          </div>
            <mat-toolbar style="background-color: #ede6e6;">
                
                <span>Order Details :</span>
            </mat-toolbar>

            <div class="row">
                <div class="column">
                    <table>
                        <tr>
                            <mat-card>
                                <mat-card-title>Order Id::</mat-card-title>
                                <mat-card>{{data.id}}</mat-card>
                            </mat-card>
                        </tr>
                        <tr>
                            <mat-card>
                                <mat-card-title>Payment Methode:</mat-card-title>
                                <mat-card>{{data.payment_method}}</mat-card>
                            </mat-card>
                        </tr>
                        <tr>
                            <mat-card>
                                <mat-card-title>City:</mat-card-title>
                                <mat-card>{{data.shipping.city}}</mat-card>
                            </mat-card>
                        </tr>
                        <tr>
                            <mat-card>
                                <mat-card-title>Address:</mat-card-title>
                                <mat-card>{{data.shipping.address_1}}</mat-card>
                            </mat-card>
                        </tr>
                    </table>
                </div>
                <div class="column">
                    <table>
                        <tr>
                            <mat-card>
                                <mat-card-title>Delivery Date:</mat-card-title>
                                <mat-card>{{data.date_completed_gmt}}</mat-card>
                            </mat-card>
                        </tr>
                        <tr>
                            <mat-card>
                                <mat-card-title>Amount:</mat-card-title>
                                <mat-card>{{data.total}}</mat-card>
                            </mat-card>
                        </tr>
                        <tr>
                            <mat-card>
                                <mat-card-title>State:</mat-card-title>
                                <mat-card>{{data.shipping.state}}</mat-card>
                            </mat-card>
                        </tr>
                    </table>
                </div>
                <div class="column">
                    <table>
                        <tr>
                            <mat-card>
                                <mat-card-title>Delivery Status:</mat-card-title>
                                <mat-card>{{data.status}}</mat-card>
                            </mat-card>
                        </tr>
                        <tr>
                            <mat-card>
                                <mat-card-title>Postcode:</mat-card-title>
                                <mat-card>{{data.shipping.postcode}}</mat-card>
                            </mat-card>
                        </tr>
                        <tr>
                            <mat-card>
                                <mat-card-title>Country:</mat-card-title>
                                <mat-card>{{data.shipping.country}} </mat-card>
                            </mat-card>
                        </tr>
                    </table>
                </div>
            </div>
        <!-- </div> -->
    <!-- </div> -->
    <div class="pad">
        <div class="mat-elevation-z8" >
            <table mat-table [dataSource]="dataSource">

                <ng-container matColumnDef="sn">
                    <th mat-header-cell class="back_color" *matHeaderCellDef> SN </th>
                    <td mat-cell class="header_align" *matCellDef="let element;let i=index;"> {{i+1}} </td>
                </ng-container>

                <ng-container matColumnDef="id">
                    <th mat-header-cell class="back_color" *matHeaderCellDef> Id </th>
                    <td mat-cell class="header_align" *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell class="back_color1" *matHeaderCellDef> Product Name </th>
                    <td mat-cell class="name" *matCellDef="let element">{{element.name}}</td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell class="back_color" *matHeaderCellDef> Quantity </th>
                    <td mat-cell class="header_align" *matCellDef="let element"> {{element.quantity}} </td>
                </ng-container>

                <ng-container matColumnDef="total">
                    <th mat-header-cell class="back_color" *matHeaderCellDef> Price </th>
                    <td mat-cell class="header_align" *matCellDef="let element"> {{element.total}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>

</div>