import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';
import { find, forEach, map, filter, sum } from 'lodash';
import { logging, element } from 'protractor';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-purchasdetails',
  templateUrl: './purchasdetails.component.html',
  styleUrls: ['./purchasdetails.component.scss']
})
export class PurchasdetailsComponent implements OnInit {
  [x: string]: any;
  displayedColumns: string[] = [ 'bill_no', 'productname', 'purchasedprice', 'quantity', 'selling price'];
  dataValue: any = [];
  newbillno: any = {};
  purchasePrice: any[] = [];
  public purPrice: any = [];
  public purchasedprice: any = {};
  public balanceprice: any = {};
  public balanced: any[] = [];
  public button: any = {}
  public amount: number
  public value: any = []
  public payload: any = {}
  constructor(public service: AppService, public router: Router, public http: HttpClient, private matDialog: MatDialog
    ,
    @Inject(MAT_DIALOG_DATA) public data: any) {


  }
  ngOnInit(): void {

    if (this.data.status === 'Completed') {
      this.service.isHide = false
    } else {
      this.service.isHide = true

    }

    const billpayload = {
      billnumbers: localStorage.getItem("billing_no")

    }
    this.http.post(environment.baseurl + 'user/getpurchasedlist', billpayload).subscribe((x: any) => {
      this.dataValue = x;
    });
  }


  editPurchasePrice(data: any) {
    const price: any = data.filter((x) => (x.purchase_price === null || x.purchase_price === undefined || x.purchase_price === ""))
    if (price.length == 0) {
      data.forEach((ele, index) => {
        ele.balance = ele.price - ele.purchase_price
        ele.status = "Verified"
        this.payload = {
          obj: JSON.stringify(data),
        }
       
      });
      this.service.verifiedData.push(this.payload)
      // this.service.verifiedData=this.payload
      this.http.post(environment.baseurl + 'user/purchaseprice', this.payload).subscribe((res) => {
        this.router.navigate(['purchasstatus']);
      })
    }


  }
  back() {
    this.matDialogRef = this.matDialog.closeAll()
    // this.service.show=false;
    // this.router.navigate(['purchasstatus']);

  }

}
