
<div class="row">
    <div class="col-md-4 col-lg-4">
        <div class="pad">
            <h1>Others</h1>
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSourceAll">

                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="sn">
                        <th mat-header-cell *matHeaderCellDef> SN </th>
                        <td mat-cell *matCellDef="let element"> {{element.sn}} </td>
                    </ng-container> -->
 
                    <ng-container matColumnDef="name">
                        <th class="h_pad" mat-header-cell *matHeaderCellDef> Product Name </th>
                        <td class="h_pad" mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="total_quantity">
                        <th class="h1_pad" mat-header-cell *matHeaderCellDef> Total Quantity </th>
                        <td class="h1_pad" mat-cell *matCellDef="let element"> {{element.total_quantity}} </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
                </table>
        
                <mat-paginator [pageSizeOptions]="[100,200,300,400]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="pad">
            <h1>Fruits</h1>
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSourceF1">

                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    
                    <!-- <ng-container matColumnDef="sn">
                        <th mat-header-cell *matHeaderCellDef> SN </th>
                        <td mat-cell *matCellDef="let element"> {{element.sn}} </td>
                    </ng-container> -->

                    <ng-container matColumnDef="name">
                        <th class="h_pad" mat-header-cell *matHeaderCellDef> Product Name </th>
                        <td class="h_pad" mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
        
                    <ng-container matColumnDef="total_quantity">
                        <th class="h1_pad" mat-header-cell *matHeaderCellDef> Total Quantity </th>
                        <td class="h1_pad" mat-cell *matCellDef="let element"> {{element.total_quantity}} </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
                </table>
        
                <mat-paginator [pageSizeOptions]="[100,200,300,400]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="pad">
            <h1>Vegetables</h1>
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSourceV1">
     
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="sn">
                        <th mat-header-cell *matHeaderCellDef> SN </th>
                        <td mat-cell *matCellDef="let element"> {{element.sn}} </td>
                    </ng-container> -->

                    <ng-container matColumnDef="name">
                        <th class="h_pad" mat-header-cell *matHeaderCellDef> Product Name </th>
                        <td class="h_pad" mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
        
                    <ng-container matColumnDef="total_quantity">
                        <th class="h1_pad" mat-header-cell *matHeaderCellDef> Total Quantity </th>
                        <td class="h1_pad" mat-cell *matCellDef="let element"> {{element.total_quantity}} </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[100,200,300,400]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
    
</div>
