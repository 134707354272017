// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  imagesPath: 'http://139.162.27.79:3020/user/getUserImages?path=',
  baseurl:'http://139.162.27.79:3020/',
   KbwcUrl:'http://orders.kumaribasket.com/wc/',
  //  imagesPath: 'http://localhost:4200/auth/user/getUserImages?path=',
  // baseurl:'http://localhost:4200/auth/',
  // KbwcUrl:'http://localhost:4200/api/',
};  


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
