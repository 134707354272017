import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from '../app.service';

@Component({
  selector: 'app-deliverydetails',
  templateUrl: './deliverydetails.component.html',
  styleUrls: ['./deliverydetails.component.scss']
})
export class DeliverydetailsComponent implements OnInit {
  displayedColumns: string[] = [ 'sn', 'id', 'name', 'quantity', 'total'];
   dataSource: any = [];
  matDialogRef: void;
  constructor( public service: AppService,
               public router: Router,
               private matDialog: MatDialog,
               @Inject(MAT_DIALOG_DATA) public data: any) {
                // this.appService.show.next(true);
    }

  ngOnInit(): void {
    this.dataSource = this.data.line_items;
    
  }
  back() {
    this.matDialogRef=this.matDialog.closeAll();
}
}
