<div class="pad" >
<div class="btn-group-item1"style="margin-top: 41px;margin-left: 30px;">
    <h2>Order Details</h2>
     </div>
       <div class="row">
           <div class="column">
               <table>
                       <tr>
                           <mat-card>
                               <mat-card-title>Customer Name:</mat-card-title>
                               <mat-card>{{billing.first_name}} {{billing.last_name}}</mat-card>
                           </mat-card>
                       </tr>
                       <tr>
                           <mat-card>
                               <mat-card-title>Mobile number:</mat-card-title> 
                               <mat-card>{{billing.phone}}</mat-card>
                           </mat-card>
                       </tr>
                       <tr>
                           <mat-card>
                               <mat-card-title>City:</mat-card-title>
                               <mat-card>{{billing.city}}</mat-card>
                           </mat-card>
                           
                       </tr>
                       <tr>
                           <mat-card>
                               <mat-card-title>Address:</mat-card-title>
                               <mat-card>{{billing.address_1}}</mat-card>
                           </mat-card>
                       </tr>
               </table>
           </div>
           <div class="column">
            <table>
                    <tr>
                        <mat-card>
                            <mat-card-title>User name:</mat-card-title>
                            <mat-card>{{user.username}}</mat-card>
                        </mat-card>
                    </tr>
                    <tr>
                        <mat-card>
                            <mat-card-title>Email:</mat-card-title>
                            <mat-card>{{billing.email}}</mat-card>
                        </mat-card>
                    </tr>
                    <tr>
                        <mat-card>
                            <mat-card-title>State:</mat-card-title>
                            <mat-card>{{billing.state}}</mat-card>
                        </mat-card>
                    </tr>
            </table>
        </div>

        <div class="column">
            <table>                        
                    <tr>
                        <mat-card>
                            <mat-card-title>Customer Id:</mat-card-title>
                            <mat-card>{{user.id}}</mat-card>
                        </mat-card>
                    </tr> 
                    <tr>
                        <mat-card>
                            <mat-card-title>Postcode:</mat-card-title>
                            <mat-card>{{billing.postcode}}</mat-card>
                        </mat-card>
                    </tr>
                     <tr>
                        <mat-card>
                            <mat-card-title>Country:</mat-card-title>
                            <mat-card>{{billing.country}}  </mat-card>
                        </mat-card>
                    </tr>                              
            </table>
            
        </div>
    </div>
        </div>
