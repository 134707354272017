import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
// import * as jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep, each, filter, forEach, isEmpty, map, mapValues, orderBy, snakeCase, values } from 'lodash';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { setTheme } from 'ngx-bootstrap/utils';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AppService } from '../app.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderdetailsComponent } from '../orderdetails/orderdetails.component';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { element } from 'protractor';
import { environment } from 'src/environments/environment';
import { FormControl, NumberValueAccessor } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { time } from 'console';
import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';

// import { verify } from 'crypto';

interface names {
  value: string;
  viewValue: string;
}

export interface orders {
  sn: number;
  id: number;
  postcode: number;
  delivery_time: number;
  delivery_date: number;
  name: string;
  address: string;
  email: string;
  phone: number;
  status: string;
  total: number;

}
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  // dateChange(): EventEmitter<MatDatepickerInputEvent<D>> 
  selectedValue: string;
  public Person: any = [];
  public Allorderid: any = [];
  public products: any;
  public statusid: object;
  public orders: any = [];
  public status: any = [];
  public id: any = [];
  public title = 'kumariBasketWeb';
  //  public positionOptions: TooltipPosition[]='above';
  //  position = new FormControl(this.positionOptions[0]);
  public selection = new SelectionModel(true, []);
  public displayedColumns: string[] = ['select', 'id', 'postcode', 'delivery_date', 'name', 'status', 'total', 'assignto', 'action'];
  public dataSource = new MatTableDataSource(this.orders);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  public orderPDF: any[] = [];
  public isSelect: any = false;
  public totalAmount: any = 0;
  public groupDatas: any[] = [];
  public sampleOrder: any = [];
  public isStore: any = false;
  public search: any = '';
  public isload = '';
  public bsRangeValue: Date[];
  public bsValue = new Date();
  public isIdSearch = false;
  public ids = '';
  public isLoading = new BehaviorSubject<boolean>(false);
  public getid: number = 0;
  public finalarray = [];
  public allproducts: any;
  selvalue: any;
  public isView = true;
  public selectedvalues: any[] = [];
  public deliverypersons: any = [];
  public dropDown: any;
  public isdropDown = true;
  public data: any = [];
  public allorderid: any = [];
  public orderid: any;
  public orderids: any = {};
  public seldropdown: any = {};
  public value: any;
  public selectedvalue: any;
  public ordersData: any = [];
  public Deliveryman: any;
  public GetDeliverymans: any;
  public isDeliveryman: boolean = false;
  public dPerson: any = [];
  public isAssign: boolean = false;
  public listOfId: any = []
  public cusId: any = [];
  public customerDetails: any = [];
  public ordercount: any = [];
  public selectedOption = 'processing'
  public newEntry: any = [];
  public disabled: boolean = false;
  public event: any;
  public ProductsValue: any = [];
  public isProduct: boolean = false;
  public payLoad: any = {}
  public date = new Date().toISOString().slice(0, 10)

  public orderTotalByCategory = {
    D1: 0,
    F1: 0,
    V1: 0,
    G1: 0,
    G2: 0,
    S1: 0
  };
  matDialogRef: MatDialogRef<OrderdetailsComponent>;
  @Output()
  dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
  public obj: any = {};
  public payload: any = {}

  ngAfterViewInit() {

    // setTimeout(() => this.orders.paginator = this.paginator);
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.selectedOption = "processing"
    this.appService.purchas = [];
    this.getAllOrder();
    this.http.get(environment.baseurl + 'user/getdropdown').subscribe((x) => {
      this.deliverypersons = x

    })
    this.bsRangeValue = [this.bsValue];
    this.getcustomer();


  }

  constructor(public appService: AppService,
    public router: Router,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    public http: HttpClient,
    private cdr: ChangeDetectorRef,

    private matDialog: MatDialog) {
    this.appService.isLogin.next(true);
    setTheme('bs3');
    this.appService.loading.subscribe(data => {
      // this.appService.show.next(true);
      this.isLoading = new BehaviorSubject<any>(data);
    });
  
  }
// public insertOrder(){
//   let allOrder:any=[]
//   console.log(this.orders);
  
//   // this.orders.forEach((ele:any) => {
//   //   let date = ele.delivery_date
//   //      console.log(date);
  
//   //   const payload = {   
//   //     orderid:ele.id,
//   //     date:parseInt(ele.delivery_date),
//   //     time:ele.delivery_time,
//   //     status: 'processing'
//   //   };
//   //   allOrder.push(payload)
//   // });
// // console.log('111111',allOrder);
//     this.http.post(environment.baseurl + 'user/insertAllOrder', this.orders).subscribe((res) => {
//   //  console.log(res);

//   })
// }

  public getAllOrder() {   //Get a Order from Woo-commerece

    this.isload = 'load';
    this.sampleOrder = [];
    let i = 0;
    this.appService.loading.next(true);
    const p = 'processing';
    const c = 'failed';
    //   // ${p}&${c}
    if (this.selectedOption == "processing") {
      this.appService.getWoo(this.appService.apiurl.kumari.url, [this.selectedOption, '100', '1']).subscribe(datas => {
        // this.appService.getWoo(this.appService.apiurl.kumari.url, ['10', '1']).subscribe(data => {
        this.orders = datas;
        for (const it of this.orders) {
          this.cusId.push({ id: it.customer_id });
        }
        this.getCus();
        this.allorderid = [];
        for (const it of this.orders) {
          this.allorderid.push({ id: it.id });
        }
        // this.insertOrder();
        this.getAllorderId();
        for (const order of this.orders) {
          let date: any = '';
          let time: any = '';
          if (order.meta_data.length) {
            date = order.meta_data.find((x) => x.key === 'delivery_date');
            time = order.meta_data.find((x) => x.key === 'delivery_time');
          }
          order.postcode = order.shipping.postcode;
          order.delivery_date = date.value;
          order.delivery_time = time.value;
          order.name = order.billing ? `${order.billing.first_name} ${order.billing.last_name}` : '';
          order.address = order.shipping ? `${order.shipping.address_1},${order.shipping.address_2},${order.shipping.city},${order.shipping.postcode}, ${order.shipping.state}` : '';
        }
        // this.http.post(environment.baseurl + 'user/insertAllOrder', this.orders).subscribe((res) => {
          
        // })
        this.sampleOrder = orderBy(this.sampleOrder, ['postcode'], ['asc']);
        this.sampleOrder = this.generateSerialNumber(this.sampleOrder);
        this.dataSource.data = this.sampleOrder;
        this.ordersData = this.orders;
        this.selection = new SelectionModel(true, []);
        this.appService.loading.next(false);
        this.dataSource = new MatTableDataSource(this.orders);
        this.dataSource.paginator = this.paginator;
      
      });
    } else if (this.event.value == "All") {
      this.appService.getWoo(this.appService.apiurl.kumari.allurl, ['100', '1']).subscribe(data => {
        this.orders = data;
        for (const it of this.orders) {
          this.cusId.push({ id: it.customer_id });
        }
        this.getCus();
        this.allorderid = [];
        for (const it of this.orders) {
          this.allorderid.push({ id: it.id });
        }
        // this.insertOrder();
        this.getAllorderId();
        for (const order of this.orders) {
          let date: any = '';
          let time: any = '';
          if (order.meta_data.length) {
            date = order.meta_data.find((x) => x.key === 'delivery_date');
            time = order.meta_data.find((x) => x.key === 'delivery_time');
          }
          order.postcode = order.shipping.postcode;
          order.delivery_date = date.value;
          order.delivery_time = time.value;
          order.name = order.billing ? `${order.billing.first_name} ${order.billing.last_name}` : '';
          order.address = order.shipping ? `${order.shipping.address_1},${order.shipping.address_2},${order.shipping.city},${order.shipping.postcode}, ${order.shipping.state}` : '';
        }
        // console.log(this.orders);
        // this.http.post(environment.baseurl + 'user/insertAllOrder', this.orders).subscribe((res) => {
        //  console.log(res);       
        // })
        this.sampleOrder = orderBy(this.sampleOrder, ['postcode'], ['asc']);
        this.sampleOrder = this.generateSerialNumber(this.sampleOrder);
        this.dataSource.data = this.sampleOrder;
        this.ordersData = this.orders;
        this.selection = new SelectionModel(true, []);
        this.appService.loading.next(false);
        this.dataSource = new MatTableDataSource(this.orders);
        this.dataSource.paginator = this.paginator;
       
      });
    }
  }
  public getCus() {    // Differentiate 1st Order    
    this.http.post(environment.baseurl + 'user/getcustomer', this.cusId).subscribe((res) => {
      this.listOfId = res;
      this.orders.forEach((x) => {
        const val = this.listOfId.find((c) => c == x.customer_id)
        if (val) {
          x.isab = true
        }
        if (!val) {
          x.isab = false
        }
      })
    })
  }
  // public getorderid() {    //Send a Payload to Db and get a Db status;
  //   this.getDeliveryman();
  //   let i = 0;
  //   this.http.post(environment.baseurl + 'user/getstatus', this.allorderid).subscribe((res) => {
  //     this.Allorderid = res;
  //     this.orders.map(x => {
  //       Object.assign(x, { deliveryman: x.assigned_to });

  //       this.GetDeliverymans.map(y => {
  //         if (x.id === y.orderid) {
  //           return x.deliveryman = y.assigned_to;
  //         }
  //       })

  //     })
  //     this.newEntry.forEach(x => {
  //       const filterdata = this.Allorderid.find((y) => {
  //         if (y.orderid === x.id) {
  //           x.status = y.status
  //           this.orders=this.newEntry;
  //         }
  //       });
  //     })
  //   });
  // }
  public getAllorderId() {
    this.getDeliveryman();
    let i = 0;
    this.http.post(environment.baseurl + 'user/getstatus', this.allorderid).subscribe((res) => {
      this.Allorderid = res;
      this.orders.map(x => {
        Object.assign(x, { deliveryman: x.assigned_to });
        this.GetDeliverymans.map(y => {
          if (x.id === y.orderid) {
            return x.deliveryman = y.assigned_to;
          }
        })
      }

      )
      this.orders.map(x => {
        Object.assign(x, { completed_by: x.assigned_to });
        this.Person.map(y => {
          if (x.id === y.orderid) {
            return x.completed_by = y.assigned_to;
          }
        })
      })
      this.orders.forEach(x => {
        const filterdata = this.Allorderid.find((y) => {
          if (y.orderid === x.id) {
            x.status = y.status;
            x.delivery_date = y.deliverydate;
            // x.id= y.orderid;
            
            // x.delivery_time= y.timeslot;
          }
        });
      })
    });
  }

  index;
  onChange(e, i) {
    this.index = i;  //assign the Order to DeliveryPerson
    this.isAssign = true;
    this.dropDown = e;
  }

  changeDeliveryman(e, i) {
    this.dropDown = e;
    this.index = i;
    this.isDeliveryman = true;
  }

  public isAllSelected() { //Select mulitiple values
    const numSelected = this.selection.selected.length;
    const numRows = this.orders.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.orders.forEach(row => this.selection.select(row));
  }
  getDeliveryman() {
    this.http.post(environment.baseurl + 'user/getDeliveryman', null).subscribe((res) => {
      this.GetDeliverymans = res;
    })

  }
  reAssign(data, i) {  //Re-assign the Order to DeliveryPerson
    this.index = !i;
    this.Deliveryman = false;

    let payload = {
      orderid: data.id,
      assigned_to: this.dropDown
    }
    this.http.post(environment.baseurl + 'user/updateDeliveryman', payload).subscribe((res) => { })
    this.getAllOrder();

  }

  public gotoPurchased() {  //Go to Purchased and Update the Status 
    const newOrders = this.orders.filter((element: any) => (element.status == 'processing' || element.status == 'Not-verify'));
    if (this.isSelect === true) {
      if (this.selection.selected.length > 0 ) {
      this.selection.selected.forEach(element => {
        // if (element.status == 'processing' || element.status == 'Not-verify') {
          this.payLoad = {
            id: element.id,
            products: element.line_items,
            deliverydate:this.date,
            deliverytime: element.delivery_time,
            status: element.status == 'Not-verify' ? 'Verified' : 'Purchased',
            isProduct: true
          }
          this.ProductsValue.push(this.payLoad);
        // }
      });
      this.http.post(environment.baseurl + 'user/purchasedProduct', this.ProductsValue).subscribe((res) => {
        this.toastr.success('Value added successfully')
        this.getAllOrder();
      })
    }else {
      this.toastr.warning('Choose atleast one Order')
    }
    } else {
      const newOrders = this.orders.filter((element: any) => ((element.status == 'processing' || element.status == 'Not-verify') && this.date === element.delivery_date));
      const orderDifference: any[] = [];
      for (const it of this.selection.selected) {
        const data = this.selection.selected.find((x) => x.id === x.id && x.delivery_date === this.date);
        if (data) {
          orderDifference.push(data);
        }
      }
      if (this.selection.selected.length > 0) {
        console.log(this.selection.selected);
        
        if (newOrders.length != 0 && orderDifference.length === newOrders.length &&
          this.selection.selected.length === orderDifference.length) {
          this.selection.selected.forEach(element => {
            this.payLoad = {
              id: element.id,
              products: element.line_items,
              deliverydate: element.delivery_date,
              deliverytime: element.delivery_time,
              status: element.status == 'Not-verify' ? 'Verified' : 'Purchased',
              isProduct: true
            }
            this.ProductsValue.push(this.payLoad);
          });

          this.http.post(environment.baseurl + 'user/purchasedProduct', this.ProductsValue).subscribe((res) => {
            this.toastr.success('Value added successfully')
          this.getAllOrder();
          })
        }
        else if (this.selection.selected.length) {
          this.toastr.warning('Choose all valid Orders')
        }
      } else {
        this.toastr.warning('Choose atleast one Order')
      }
    }
  }

  Assigned(element, i) {  //Send a Customer details and Assigned to the Delivery Person
    this.index = !i;
    this.isAssign = false;
    const cusdet = this.orders.find((x) => x.id == this.selectedvalue)
    const payload = {
      customerNames: element.name,
      customerAddress: element.address,
      customerPhonenumber: element.billing.phone,
      customerOrderdate: this.date,
      cusPaymentmethod: element.payment_method,
      cusPincode: element.postcode,
      cusid: element.customer_id,
      amounts: element.total,
      cusOrderid: element.number,
      deliverystatus: "processing",
      value: this.dropDown,
    }
    this.http.post(environment.baseurl + 'user/customerdetailspayload', payload).subscribe((res) => { })

    const dropvalue = {
      id: element.number,
      deliverystatus: "OnDelivery",

    }
    this.http.post(environment.baseurl + 'user/assignedto', dropvalue).subscribe((res) => {

    })

    this.getAllOrder();
  }
  public getProducts() {
    this.appService.getWoo(environment.KbwcUrl + 'wp-json/wc/v3/products', []).subscribe(data => {

    });
  }

  // public generatePDF() {
  //   // let fields = this.generateFields();
  //   if (this.selection.selected.length) {
  //     const pdfDatas = [];
  //     this.groupDatas = [];
  //     this.orderPDF = [];
  //     this.totalAmount = 0;
  //     let productIds = [];
  //     const orderList = this.mappingQuantity();
  //     for (const item of orderList) {
  //       if (pdfDatas.length) {
  //         const data = pdfDatas.find((x) => x.product_id === item.product_id && x.sku1 === item.sku1);
  //         if (data) {
  //           data.id = `${data.id}, ${item.id}`;
  //           data.quantity = (data.quantity + item.quantity);
  //           data.total_quantity = `${data.total_quantity} + ${item.total_quantity}`;
  //           data.total = (parseInt(data.total) + parseInt(item.total)).toString();
  //           data.product_type = data.product_type ? `${data.product_type} + ${item.product_type}` : '';
  //           productIds.push(item.product_id);
  //         }
  //         else {
  //           pdfDatas.push(item);
  //           productIds.push(item.product_id);
  //         }
  //       } else {
  //         pdfDatas.push(item);
  //         productIds.push(item.product_id);
  //       }
  //     }
  //     productIds = [...new Set(productIds)];
  //     let orignalData: any = [];
  //     for (const it of productIds) {
  //       let quty: any = [];
  //       let totalquantity: any;
  //       let quantity: any;
  //       let id: any;
  //       let pType: any = [];
  //       let total: any;
  //       const pdata = pdfDatas.filter((x) => x.product_id === it);
  //       // if (pdata && pdata[0].product_id === 1162) {

  //       // }
  //       for (const pit of pdata) {

  //         quty = pit.total_quantity.split('+');
  //         pType = pit.product_type.split('+');
  //         totalquantity = totalquantity ? `${totalquantity}, ${pType[0]} ${quty[0]} x ${quty.length}` : `${pType[0]} ${quty[0]} x ${quty.length}`;
  //         quantity = quantity ? (quantity + pit.quantity) : pit.quantity;
  //         id = id ? `${id}, ${pit.id}` : pit.id;
  //         total = total ? (parseInt(total) + parseInt(pit.total)).toString() : pit.total;
  //       }
  //       let o_id = id.toString().split(',');
  //       o_id = [...new Set(o_id)];
  //       pdata[0].total_quantity = totalquantity;
  //       pdata[0].quantity = quantity;
  //       pdata[0].id = o_id.join(',');
  //       pdata[0].total = total;
  //       orignalData.push(pdata[0]);
  //     }
  //     orignalData = orderBy(orignalData, ['name'], ['asc']);

  //     for (const d of orignalData) {
  //       // this.totalAmount = this.totalAmount + parseInt(d.total);
  //       this.totalAmount = (parseFloat(this.totalAmount) + parseFloat(d.total)).toFixed(1);
  //       d.group = d.sku.substring(2, 0);
  //       this.groupDatas.push(d.group);
  //       this.groupDatas = [...new Set(this.groupDatas)];
  //     }
  //     const d1 = this.groupDatas.find((x) => x === 'D1');
  //     const f1 = this.groupDatas.find((x) => x === 'F1');
  //     const v1 = this.groupDatas.find((x) => x === 'V1');
  //     if (d1) {
  //       this.groupDatas.splice(this.groupDatas.findIndex((x) => x === d1), 1);
  //       this.groupDatas.push(d1);
  //     }
  //     if (f1) {
  //       this.groupDatas.splice(this.groupDatas.findIndex((x) => x === f1), 1);
  //       this.groupDatas.push(f1);
  //     }
  //     if (v1) {
  //       this.groupDatas.splice(this.groupDatas.findIndex((x) => x === v1), 1);
  //       this.groupDatas.push(v1);
  //     }

  //     for (let i = 0; i < this.groupDatas.length; i++) {
  //       const result = orignalData.filter((x) => x.group === this.groupDatas[i]);
  //       each(result, res => {
  //         if (res.group === 'F1') {
  //           this.orderTotalByCategory.F1 = this.orderTotalByCategory.F1 + parseFloat(res.total);
  //         } else if (res.group === 'V1') {
  //           this.orderTotalByCategory.V1 = this.orderTotalByCategory.V1 + parseFloat(res.total);
  //         } else if (res.group === 'G1') {
  //           this.orderTotalByCategory.G1 = this.orderTotalByCategory.G1 + parseFloat(res.total);
  //         } else if (res.group === 'G2') {
  //           this.orderTotalByCategory.G2 = this.orderTotalByCategory.G2 + parseFloat(res.total);
  //         } else if (res.group === 'D1') {
  //           this.orderTotalByCategory.D1 = this.orderTotalByCategory.D1 + parseFloat(res.total);
  //         } else if (res.group === 'S1') {
  //           this.orderTotalByCategory.S1 = this.orderTotalByCategory.S1 + parseFloat(res.total);
  //         }
  //       });
  //       this.orderPDF.push(result);


  //     }
  //     const data = this.manipulatepdftableData();
  //     this.appService.purchas = this.orderPDF;
  //   } else {
  //     this.toastr.error('Please select any one order to generate PDF', 'Error');
  //   }
  // }


  //     }
  //     const data = this.manipulatepdftableData();
  //     this.appService.purchas = this.orderPDF;
  //   } else {
  //     this.toastr.error('Please select any one order to generate PDF', 'Error');
  //   }
  // }

  public generateFields() {
    const fields: any[] = [];
    if (this.selection.selected.length) {
      for (const item of this.selection.selected) {
        for (const i of item.line_items) {
          fields.push(i.name);
        }
      }
    }
    return [...new Set(fields)];
  }

  public mappingQuantity() {
    const pdfDatas = [];
    const fff: any = [];

    for (const item of this.selection.selected) {
      for (const i of item.line_items) {
        if (i.product_id === 1162) {

        }
        let weigth: any;
        let productType: any;
        if (i.meta_data.length) {
          weigth = i.meta_data.find((x) => x.key === 'pa_weight' || x.key === 'pa_size');
          productType = i.meta_data.find((x) => x.key === 'pa_what-type-do-you-need');
        }
        let quantityData = weigth ? weigth.display_value : '';
        i.id = item.id;
        if (quantityData !== '') {
          if (i.quantity !== 1) {
            const qty: any = [];
            for (let j = 1; j <= i.quantity; j++) {
              qty.push(quantityData);
            }
            quantityData = qty.join(' + ');
          }
        } else {
          quantityData = i.quantity;
        }
        i.total_quantity = `${quantityData}`;
        const obj = {
          name: i.parent_name ? i.parent_name : i.name,
          id: item.id,
          quantity: i.quantity,
          sku: i.sku,
          sku1: i.sku,
          total_quantity: i.total_quantity,
          product_type: productType ? productType.display_value : '',
          product_id: i.product_id,
          total: i.total
        };
        pdfDatas.push(obj);
      }
    }
    return pdfDatas;
  }

  public generatePDFDownload(data) {
    const d: any = [{
      text: 'first column',
      fillColor: '#c8cbcf',
      color: '#00FFFF',
      fontsize: '22px'
    }, {
      text: 'first s',
      fillColor: '#c8cbcf',
      color: '#00FFFF',
    }, {
      text: 'first q',
      fillColor: '#c8cbcf',
      color: 'white',
    }, {
      text: 'first ed',
      fillColor: '#c8cbcf',
      color: '#00FFFF',
    }, {
      text: 'first ed',
      fillColor: '#c8cbcf',
      color: '#00FFFF',
    }, {
      text: 'first ed',
      fillColor: '#c8cbcf',
      color: '#00FFFF',
    },
    {
      text: 'first ed',
      fillColor: '#c8cbcf',
      color: '#00FFFF',
    },];
    const docDefinitions = {
      header: 'kumaribasket.com',
      content: [
        {
          // layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],

            body: [
              d,
              // ['eee1','eee','eee','eee','eee','eweree'],
              // ['eeswe2','eewe','eee','eee','eee','eewere'],
              // ['eee3','eweee','eweree','eewere','eewre','eee']
              ...data,
            ]
          },
        }
      ]
    };

    pdfMake.createPdf(docDefinitions).download();
  }

  public generatePDF3() {

    this.id = this.search.split(',');
    this.id = [...new Set(this.id)];
    this.getAllOrder();
  }

  public manipulatepdftableData() {
    const pdfdata: any = [];
    for (const obj of this.orderPDF) {
      const filter: any = [];
      for (const [key, value] of Object.entries(obj)) {
        const val = value ? value : '';
        filter.push(val.toString());
      }
      pdfdata.push([...filter]);
    }
    return pdfdata;
  }

  applyFilter(filterValue: string) {   // filter for Orderid
    // this.orders = this.ordersData;
    this.orderPDF = [];
    this.selection = new SelectionModel(true, []);
    filterValue = filterValue.trim().toLowerCase();
    // this.dataSource.filter = filterValue;
    this.dataSource = this.ordersData.filter(x => x.id.toString().includes(filterValue))
  }


  addEvent(s, date) {   //filter for Date Picker
    this.orderPDF = [];
    this.selection = new SelectionModel(true, []);
    let ordersval: any = [];
    if (date) {
      if (date && date.length) {
        const startDate = moment(date[0]).format('YYYY-MM-DD');
        const endDate = moment(date[1]).format('YYYY-MM-DD');
        for (let i = 0; i < this.ordersData.length; i++) {
          if (this.ordersData[i].delivery_date >= startDate && this.ordersData[i].delivery_date <= endDate) {
            ordersval.push(this.ordersData[i]);
          }
        }
        this.dataSource = ordersval;
      }
    } else {
      this.dataSource = this.ordersData;
    }
  }

  public searchIds() {
    this.appService.loading.next(true);
    this.dataSource.data = [];
    this.selection = new SelectionModel(true, []);
    this.isload = 'load';
    this.sampleOrder = [];
    this.id = this.ids ? this.ids.trim().split(',') : [];
    this.id = [...new Set(this.id)];
    this.id = this.id.map(function (el) {
      return el.trim();
    });
    const orderRequest: any = [];
    if (this.id.length) {
      for (const id of this.id) {
        if (id) {
          orderRequest.push(this.appService.getWoo(this.appService.apiurl.kumari.url_id, [id]));
          // this.appService.getdeails=orderRequest
        }
      }
      forkJoin(orderRequest)
        .subscribe(data => {

          const order: any = data;
          for (const orders of order) {
            let date: any = '';
            let time: any = '';
            if (orders.meta_data.length) {
              date = orders.meta_data.find((x) => x.key === 'delivery_date');
              time = orders.meta_data.find((x) => x.key === 'delivery_time');
            }
            orders.postcode = orders.shipping.postcode;
            orders.delivery_date = date.value;
            orders.delivery_time = time.value;
            orders.name = orders.billing ? `${orders.billing.first_name} ${orders.billing.last_name}` : '';
            orders.address = orders.shipping ? `${orders.shipping.address_1},${orders.shipping.address_2},${orders.shipping.city},${orders.shipping.postcode}, ${orders.shipping.state}` : '';
            this.sampleOrder.push(orders);


          }
          this.sampleOrder = orderBy(this.sampleOrder, ['postcode'], ['asc']);
          this.sampleOrder = this.generateSerialNumber(this.sampleOrder);
          this.dataSource.data = this.sampleOrder;
          this.selection = new SelectionModel(true, []);
          this.appService.loading.next(false);
        });
    } else if (!this.id.length) {
      this.dataSource = this.orders
      this.appService.loading.next(false);
      this.toastr.error('Need at least one order id to search', 'Error');
    }
  }

  ngAfterContentChecked() {
    // document.getElementById('title').innerHTML = this.dataservice.titleName;
    this.appService.loading.subscribe(data => {
      this.isLoading = new BehaviorSubject<any>(data);
    });
    this.cdref.detectChanges();
  }

  public generateSerialNumber(sampleOrder) {
    let i = 0;
    for (const order of sampleOrder) {
      order.sn = i + 1;
      i++;
    }
    return sampleOrder;
  }
  // public generatePDFDownload() {
  //   const doc = new jsPDF();

  //   const specialElementHandlers = {
  //     '#editor': function (element, renderer) {
  //       return true;
  //     }
  //   };

  //   const pdfTable = this.pdfTable.nativeElement;
  //   doc.setFont("helvetica");
  //   doc.setFontType("bold");
  //   doc.setFontSize(9);
  //   doc.fromHTML(pdfTable.innerHTML, 1, 1, {
  //     width: 10,
  //     heigh: 22,
  //     'elementHandlers': specialElementHandlers
  //   });

  //   doc.save('tableToPdf.pdf');
  // }

  OpenModal(data) {

    this.matDialogRef = this.matDialog.open(OrderdetailsComponent, {

      data,
      // disableClose: true
    });
    this.appService.orderview.next(data);
    this.matDialogRef.afterClosed().subscribe(() => {

      this.getAllOrder();

    });
  }


  openCustomerModel(order) {
    this.appService.customerdetails = order;
    this.router.navigate(['customerdetails']);
    this.appService.isDetails = true;
    this.appService.show.next(false);
    // this.appService.show.next(true);
  }
  openCustomer(order) {

    this.appService.customerdetails = order;
    // if (this.orders.id == this.orders.delivery_date) {
    this.appService.isDetails = false;
    // }
    this.router.navigate(['customerdetails']);
    this.appService.show.next(false);

  }

  purchasedProduct() {
    this.router.navigate(['/purchasstatus']);
  }

  public getcustomer() {  // get completed order delivery Person name 
    this.http.get(environment.baseurl + 'user/completedcustomer').subscribe((data) => {
      this.Person = data;
      this.orders.map((x: any) => {
        Object.assign(x, { deliveryman: x.assigned_to });
        this.Person.map(y => {
          if (x.id === y.orderid) {
            return x.deliveryman = y.assigned_to;
          }
        })
      })
    })
  }
  onSelect(eve: any) {
    this.event = eve
    if (eve.value == "processing") {
      this.getAllOrder()
    } else if (eve.value == "All") {
      this.getAllOrder()
    }

  }
}


