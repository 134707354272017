import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-dialogbox',
  templateUrl: './dialogbox.component.html',
  styleUrls: ['./dialogbox.component.scss']
})
export class DialogboxComponent implements OnInit {
public payload :any;
public currentDate = new Date().toISOString().slice(0, 10)
  constructor( public dialogRef: MatDialogRef<DialogboxComponent>,
   public http:HttpClient,
  //  private activeModal: NgbActiveModal,
   public app:AppService,public route:Router, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {   
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  verified(){
    this.payload={ id :this.app.matboxid};
    const payLoad={
    bill_no:this.data.bill_no,
    username:this.data.user_name,
    status:'Paid',
    description:this.data.description,
    date:this.currentDate
    }
    this.http.post(environment.baseurl + 'user/changeasverified', payLoad).subscribe((x) => {
      // if (x) {
        this.dialogRef.close();
      //   this.app.getdataValue();
      //  }
      // this.route.navigate(['costestimation']);
    })
    
  }
  public dismiss() {
    this.dialogRef.close();
  }
}
