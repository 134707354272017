<loading *ngIf="isLoading.value"></loading>
<div style="margin-top: -7px;">
  <div class="container-fluid">
    <div class="pad">
      <div class="btn-group">
        <div class="btn-group-item1">
          <div>
            <h2>Order Details</h2>
          </div>
        </div>
        <!-- <div class="btn-group-item2">
                    <button mat-flat-button class="btn_PDF" routerLink="/customer">View Customer</button>
                </div> -->

        <!-- <mat-form-field appearance="outline">
              <mat-label>Choose a date</mat-label>
              <input matInput (dateChange)="addEvent('change', $event)" [matDatepicker]="picker">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field> -->


        <!-- <div class="btn-group-item5" *ngIf="!isIdSearch">
            <button mat-flat-button class="btn_PDF" (click)="generatePDF3()">Search</button>
          </div> -->
        <!-- <div class="btn-group-item2"> -->
        <!-- <button mat-flat-button class="btn_PDF" (click)="generatePDF()">Generate AS PDF</button> -->
        <!-- </div> -->
        <div class="btn-group-item2">
          <div class="item2">
            <mat-checkbox [(ngModel)]="isSelect" [checked]="isSelect">Select Order
            </mat-checkbox>
          </div>
          <div class="item2">
            <mat-checkbox [(ngModel)]="isStore" [checked]="isStore">Report For Store
            </mat-checkbox>
          </div>
          <div class="item2">
            <mat-checkbox [(ngModel)]="isIdSearch" [checked]="isIdSearch">Need ID Search
            </mat-checkbox>
          </div>
          <div class="item2">
            <button mat-flat-button class="btn_PDF" (click)="gotoPurchased()">
              Go to Purchased
            </button>
          </div>
          <!-- <div class="item2">
            <button mat-flat-button class="btn_PDF" (click)="purchasedProduct()">
              Purchased
            </button>
          </div> -->
        </div>
        <!-- <button mat-flat-button class="btn_PDF" (click)="generatePDFDownload()">generatePDFDownload</button> -->
        <!-- <div class="btn-group-item2">
          <button mat-flat-button class="btn_PDF" (click)="Logout()">
            Logout
          </button>
        </div> -->
        <!-- <div style="text-align: right;">
        <button mat-flat-button class="btn_PDF">Assign</button>
        </div> -->
        <!-- </div> -->

      </div>

    </div>
    <div class="order-row-group">
      <div class="btn-group-item5" *ngIf="isIdSearch">
        <button mat-flat-button class="btn_PDF" (click)="searchIds()">
          Search
        </button>
      </div>
      <div class="btn-group-item4" *ngIf="!isIdSearch">
        <mat-form-field appearance="outline">
          <mat-label>Search Orders</mat-label>
          <input type="text" (keyup)="applyFilter($event.target.value)" matInput placeholder="Enter the order ID" />
        </mat-form-field>
      </div>
      <div class="btn-group-item8" *ngIf="isIdSearch">
        <mat-form-field appearance="outline">
          <mat-label>Order Ids</mat-label>
          <input type="text" matInput [(ngModel)]="ids" placeholder="Enter the orderId with comma" />
        </mat-form-field>
      </div>
      <div class="btn-group-item6">
        <input class="form-control" placeholder="Choose Date" #drp="bsDaterangepicker" bsDaterangepicker
          (ngModelChange)="addEvent('change', $event)" [(ngModel)]="bsRangeValue" />
      </div>
      <div class="btn-group-item4">
        <mat-form-field appearance="outline">
          <!-- <mat-label>Filter</mat-label> -->
          <mat-select [(ngModel)]="selectedOption" (selectionChange)="onSelect($event)">
            <mat-option value="All"> All </mat-option>
            <mat-option value="processing">Processing</mat-option>
            <!-- <mat-option value="Not-verify">Not-verify</mat-option>
            <mat-option value="Verify">Verify</mat-option>
            <mat-option value="OnDelivery">OnDelivery</mat-option> -->
            <!-- <mat-option value="Completed">Completed</mat-option> 
            <mat-option value="Cancelled">Cancelled</mat-option> -->
            <!-- <mat-option value="Returned">Returned</mat-option>  -->
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="pad1">
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="id" >
            <th mat-header-cell class="header_align" *matHeaderCellDef>
              Order Id
            </th>
            <td mat-cell class="header_align" *matCellDef="let element">
              {{ element.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="postcode" >
            <th mat-header-cell class="header_align" *matHeaderCellDef>
              Pin code
            </th>
            <td mat-cell class="header_align" *matCellDef="let element">
              {{ element.postcode }}
            </td>
          </ng-container>

          <ng-container matColumnDef="delivery_date">
            <th mat-header-cell *matHeaderCellDef class="header_align">
              Delivery date & time
            </th>
            <td mat-cell class="header_align" *matCellDef="let element">
              {{ element.delivery_date | date: "dd-MM-yyyy" }} /
              {{ element.delivery_time }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell class="back_color" *matHeaderCellDef>
              Customer Name
            </th>
            <td mat-cell class="cursor back_color" *matCellDef="let element"
              [ngClass]="{'abcolor' : element.isab == true}" (click)="openCustomerModel(element)"> {{
              element.name }} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell class="header_align" *matHeaderCellDef>
              Status
            </th>
            <td mat-cell class="header_align" *matCellDef="let element">
              {{ element.status }}
            </td>
          </ng-container>

          <ng-container matColumnDef="total">
            <th mat-header-cell class="header_align" *matHeaderCellDef>
              Total
            </th>
            <td mat-cell class="header_align" *matCellDef="let element">
              {{ element.total }}
            </td>
          </ng-container>

          <ng-container matColumnDef="assignto" >
            <th mat-header-cell class="header_align" *matHeaderCellDef style="width: 150px;text-align: center;">
              Assign to
            </th>
            <td mat-cell class="header_align" *matCellDef="let element ; let i = index">
              <div *ngIf="element.status === 'processing'|| element.status ==='Not-verify'">
                --
              </div>
              <div *ngIf="element.status === 'Return'">
                <div>Order Returned
                </div>
            </div>

              <div *ngIf="element.status === 'Completed'">
                <div>
                 {{element.completed_by}}
                </div>
              </div>
              <div *ngIf="element.status === 'Verified'">Waiting for Purchase</div>
              <div class="col-5">
                <div *ngIf="element.status ==='Purchased'" style="width: 100px;  padding: 0px;">
                  <!-- <div *ngFor="let dd of deliverypersons"> -->
                    <!-- <div *ngIf="element.deliveryman == dd.username">
                    <mat-select placeholder="Assign to" style="width:100px;text-align: center;">
                      <mat-option *ngFor="let name of deliverypersons" [value]="name.username"
                        (click)="onChange(name.username, i)">
                          {{ element.deliveryman }}

                      </mat-option>
                    </mat-select>
                  </div> -->
                  <!-- </div> -->
                  <!-- <div class="col-7"> --> 
                    <mat-select placeholder="Assign to">
                      <mat-option
                        *ngFor="let name of deliverypersons"
                        [value]="name.username"
                        (click)="onChange(name.username, i)">
                        {{ name.username }}
                      </mat-option>
                    </mat-select>
                  </div>

                <!-- </div> -->
              </div>
              <div class="col-5" >
              <div *ngIf="element.status === 'OnDelivery'" style="width: 10px;  padding: 0px;">
                <mat-select  class="deliveryman" placeholder="{{element.deliveryman}}"
                  style="width:100px;text-align: center;">
                  <mat-option *ngFor="let name of deliverypersons" [value]="name.username"
                    (click)="changeDeliveryman(name.username, i)">
                    {{ name.username }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
              <!-- <div *ngFor="let name of Person">
                <div *ngIf="element.status === 'Completed'">
                  {{name.assigned_to}}
                </div>
              </div> -->
            </td>
          </ng-container>

          <div>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="width: 150px;text-align: center;">
                Action
              </th>
              <td mat-cell *matCellDef="let element; let i = index" style="padding-left: 10px;">

                <div value="view" *ngIf="element.status === 'processing' || element.status === 'Not-verify' || element.status === 'Completed' || 
              element.status === 'Verified' ||element.status === 'OnDelivery' ||
              element.status === 'completed'|| element.status === 'cancelled'|| element.status === 'Purchased' || element.status === 'Return' ">
                  <mat-icon matTooltip="Order Details" [matTooltipPosition]="'above'"
                    (click)="openCustomer(element)">
                    info</mat-icon>
                  <mat-icon type="button" matTooltip="Product info" [matTooltipPosition]="'above'"
                    (click)="OpenModal(element)">description</mat-icon>
                  <div *ngIf="element.status === 'Purchased'">
                    <div class="row">
                      <button class="assign" *ngIf="i === index" (click)="Assigned(element,i)">
                        Assign
                      </button>
                    </div>
                  </div>
                </div>
                <div *ngIf="element.status === 'OnDelivery'">
                  <div class="row">
                    <button class="assign" *ngIf="i === index" (click)="reAssign(element,i)">
                      Re-assign
                    </button>
                  </div>
                </div>

                <!-- <div value="Cancelled" *ngIf="element.status === 'cancelled'">
              <mat-icon matTooltip="Customer info" [matTooltipPosition]="'above'" (click)="openCustomerModel(element)">
                info</mat-icon>
            </div> -->
                <!-- <div value="Completed" *ngIf="
                    element.status === 'Completed' ||
                    element.status === 'completed'">
              <mat-icon matTooltip="Customer info" [matTooltipPosition]="'above'" (click)="openCustomerModel(element)">
                info</mat-icon>
            </div> -->
              </td>
            </ng-container>
          </div>
          <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef>Symbol</th>
            <td mat-cell *matCellDef="let element">{{ element.symbol }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
        </table>
        <mat-paginator #paginator
        [pageSize]="20"
        [pageSizeOptions]="[20, 50, 100]">
        </mat-paginator>
      </div>
    </div>
  </div>

  <span *ngFor="let datas of orderPDF; let i = index">
    <!-- <div *ngIf="groupDatas[i] === 'F1'"> -->
    <div class="div-table">{{ groupDatas[i] }}</div>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">No</th>
          <th>Product Name</th>
          <th>Order Id</th>
          <th>Quantity</th>
          <th>Total Quantity</th>
          <!-- <th>Product Type</th> -->
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datas; let j = index">
          <th scope="row">{{ j + 1 }}</th>
          <td>{{ data.name }}</td>
          <td>{{ data.id }}</td>
          <td>{{ data.quantity }}</td>
          <td>{{ data.total_quantity }}</td>
          <!-- <td>{{data.product_type}}</td> -->
          <td *ngIf="!isStore">{{ data.total }}</td>
          <td *ngIf="isStore"></td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!isStore && groupDatas[i] === 'F1'" class="table-foot">
      Total: {{ this.orderTotalByCategory.F1 }}
    </div>
    <div *ngIf="!isStore && groupDatas[i] === 'V1'" class="table-foot">
      Total: {{ this.orderTotalByCategory.V1 }}
    </div>
    <div *ngIf="!isStore && groupDatas[i] === 'G1'" class="table-foot">
      Total: {{ this.orderTotalByCategory.G1 }}
    </div>
    <div *ngIf="!isStore && groupDatas[i] === 'G2'" class="table-foot">
      Total: {{ this.orderTotalByCategory.G2 }}
    </div>
    <div *ngIf="!isStore && groupDatas[i] === 'S1'" class="table-foot">
      Total: {{ this.orderTotalByCategory.S1 }}
    </div>
    <div *ngIf="!isStore && groupDatas[i] === 'D1'" class="table-foot">
      Total: {{ this.orderTotalByCategory.D1 }}
    </div>
  </span>
  <!-- kkk -->
  <!-- <div *ngIf="!isStore" class="table-foot">Total: {{ totalAmount }}</div> -->
</div>