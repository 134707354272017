
<div style="margin-top:5%;margin-left: 17px; padding: 0 10px;">
    <div class="row">
<div class="col-6">
    <h2>Purchased Bills</h2>  
</div>

<!-- <mat-form-field appearance="outline" >
  <input matInput [matDatepicker]="picker" placeholder="Current date" [formControl]="date"  (dateChange)="applyFilter($event)" >
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker panelClass="datepickerOne" ></mat-datepicker>
</mat-form-field> -->

<div class="col-6" style="text-align: right;">
  <mat-form-field appearance="outline" style="margin-right: 20px;">
    <input matInput [matDatepicker]="picker" [max]="maxDate"  placeholder="Current date" [formControl]="date"  (dateChange)="applyFilter($event)" >
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker panelClass="datepickerOne" ></mat-datepicker>
  </mat-form-field>
    <button mat-flat-button class="btn_PDF"(click)="Update()">
      Update
    </button>
  </div>
</div>
<!-- <mat-dialog-actions align="end" style="margin-bottom: -20px;">
    <button class="save">Update</button>
</mat-dialog-actions> -->

<div class="mat-elevation-z8" style="width: 100%;">  
    <table mat-table [dataSource]="dataSource">

     <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
         
        <!-- <ng-container matColumnDef="sn">
            <th mat-header-cell class="back_color" *matHeaderCellDef> SN </th>
            <td mat-cell class="header_align" *matCellDef="let element;let j=index;"> {{j+1}} </td>
        </ng-container> -->
         
        <ng-container matColumnDef="purchasername" name="purchasername" ngDefaultControl [(ngModel)]="dataSource">
            <th mat-header-cell class="back_color1" *matHeaderCellDef> Purchaser Name   </th>
            <td mat-cell class="name" *matCellDef="let element">{{element.purchasername}}</td>
        </ng-container>

        <ng-container matColumnDef="purchasedate" name="purchasedate" ngDefaultControl [(ngModel)]="dataSource">
            <th mat-header-cell class="back_color1" *matHeaderCellDef> Purchase Date  </th>
            <td mat-cell class="name" *matCellDef="let element">{{element.purchasedate | date: "dd-MM-yyyy" }}</td>
        </ng-container>

        <ng-container matColumnDef="billno"  name="bill_no" ngDefaultControl [(ngModel)]="dataSource">
            <th mat-header-cell class="back_color" *matHeaderCellDef> Bill No </th>
            <td mat-cell class="header_align" *matCellDef="let element"> {{element.billing_no}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="purchased status" name="purchase_status" ngDefaultControl [(ngModel)]="dataSource">
            <th mat-header-cell class="back_color" *matHeaderCellDef>Purchased Status </th>
            <td mat-cell class="header_align" *matCellDef="let element"> {{element.purchase_status}}</td>
        </ng-container> -->

        <ng-container matColumnDef="status" name="status" ngDefaultControl [(ngModel)]="dataSource">
            <th mat-header-cell class="back_color" *matHeaderCellDef>Status</th>
            <!-- <td mat-cell class="header_align" *matCellDef="let element"></td>  -->
                <td mat-cell class="header_align" *matCellDef="let element" > {{element.status}} </td>
                 <!-- <div *ngIf="service.isPrice===false">Not-verify</div> -->
                 <!-- <div *ngIf="service.isPrice===true">Verify</div> -->
        </ng-container>

        
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element;let index=index">
                <!-- <span class="material-icons" style="margin-left: 10px;" (click)="view(element)">find_in_page</span>  -->
                <mat-icon matTooltip="view page" [matTooltipPosition]="'above'"
                (click)="view(element)" style="color: rgba(0,0,0,.54);cursor: pointer;">info</mat-icon>
                <mat-icon matTooltip="download" [matTooltipPosition]="'above'"
                (click)="download(index)" style="color: rgba(0,0,0,.54);cursor: pointer;">
                download</mat-icon>
            </td>
         </ng-container>
         
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <!-- <mat-paginator #paginator
        [pageSize]="20"
        [pageSizeOptions]="[20, 50, 100]">
        </mat-paginator> -->
</div>
</div>