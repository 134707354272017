import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeliverydetailsComponent } from '../deliverydetails/deliverydetails.component';
import { filter, forEach, find } from 'lodash';

@Component({
  selector: 'app-customerdetails',
  templateUrl: './customerdetails.component.html',
  styleUrls: ['./customerdetails.component.scss']
})

export class CustomerdetailsComponent implements OnInit {
  constructor(public appService: AppService,
              public router: Router,
              private matDialog: MatDialog) {
                // this.appService.show.next(true);
    this.order=this.appService.customerdetails;
    this.getcustomerDetails();
  }
  order: any = [];
  totalorder: any = [];
  orderData: any = [];
  user: any = [];
  billing: any = [];
  public displayedColumns: string[] = ['sn', 'id', 'postcode', 'delivery_date', 'name', 'address', 'payment_method', 'total'];
  public dataSources: any = [];
  matDialogRef: MatDialogRef<DeliverydetailsComponent>;
  public dataSource = new MatTableDataSource(this.dataSources);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  columndefs: string[] = ['name', 'code'];
  // public dataSource = this.totalorder
  ngOnInit(): void {

    // this.dataSource=this.appService.getDetails()
  
    
   }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
  
  }

 
  getcustomerDetails() { 
    this.appService.getWoo(this.appService.apiurl.kumari.customerID, [this.order.customer_id]).subscribe(data => {
      this.user = data;
      this.billing = this.user.billing;
     
      this.appService.getWoo(this.appService.apiurl.kumari.customerByOrder, [this.order.customer_id]).subscribe(alldata => {
        this.orderData = alldata;
        this.orderData.forEach((y) => {
          
          
        });
        if (this.appService.isDetails == false) {
     
          this.dataSources = [this.orderData.find((x) => x.id == this.order.id)];
          this.dataSource = new MatTableDataSource(this.dataSources);
          this.dataSource.paginator = this.paginator;
          // this.dataSources = this.order
         }
         else if(this.appService.isDetails == true) {
          this.dataSources = this.orderData;
          this.dataSource = new MatTableDataSource(this.dataSources);
          this.dataSource.paginator = this.paginator;
         }
      });
    

     
    });
  }
  goback(){
    this.router.navigate(['order']);
  }
  openpopup(element) {
    this.matDialogRef = this.matDialog.open(DeliverydetailsComponent, {
      data: element,
      // disableClose: true
    });
  }

  // openCustomerModel(element) { }

}

