import { DataSource } from '@angular/cdk/collections';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Input } from '@angular/core';
// import * as OAuth from 'oauth-1.0a';
// import * as CryptoJS from 'crypto-js';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public show: Subject<any> = new Subject();

  checkorder: any = [];
  public dataScource = new Subject<any[]>();
  // dataSource = new Subject<dataSource[]>();
  getdeails: any = [];
  customerdetails: any = [];
  toaster: any;
  getId: any;
  public islogin:boolean=false;
  public login: Subject<any> = new Subject();
  // public isButton: boolean = true;
  public updateButton:boolean=true;
  public authenticatedUser = new BehaviorSubject<any>(undefined);
  public isLogged: boolean= false;
  public subbill = new Subject<string>();
  public isload: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public orders: any = [];
  public getbill: any;
  public getValue: any;
  public loading: Subject<any> = new Subject();
  public isMoreCustomers: Subject<any> = new Subject();
  public isMoreOrders: Subject<any> = new Subject();
  public orderview: Subject<any> = new Subject();
  public productdata: Subject<any> = new Subject();
  public isLoggedIn$: BehaviorSubject<boolean>;
  public ids: Subject<any> = new Subject();
  public matboxid: any;
  public isPrice: boolean=false;
  public isLogin: Subject<any> = new Subject(); 
  public dataVal:Subject<any> = new Subject();
  public orderinfo: Subject<any> = new Subject();
  public mailPayload: Subject<any> = new Subject();
  public verifiedData: any = [] 
  public isSaveHide: boolean = true;
  public isDetails: boolean = true;
  public dataSources:any=[]
  public menuList:any=[]
  public isHide: boolean = true;
  public totalamount: Subject<any> = new Subject();
//   product():Observable<any>{
// return this.productdata.asObservable();
//   }
  public purchas: any[] = [];
  public apiAuthentication = 'Y2tfYTBiZTMyNmMwMzQ2ZDQ1OWMxMDIyY2RjYjE0ZmQ3NmQ5ZGJkM2ZkNjpjc18yNDA3YzkxZWM2ZmYyYTM4OWI4OTdlODdkODdiZThmMmE4YzQ4Yjc0';
  public apiurl = {
    kumari: {
      // url: "https://kumaribasket.com/wp-json/wc/v3/orders/?status={0}&status={1}&per_page={2}&page={3}",
      // url: environment.KbwcUrl+'wp-json/wc/v3/orders/?per_page={0}&page={1}',
      url: environment.KbwcUrl+'wp-json/wc/v3/orders/?&status={0}&per_page={1}&page={2}',
      allurl:environment.KbwcUrl+'wp-json/wc/v3/orders/?per_page={0}&page={1}',
      url_id: environment.KbwcUrl+'wp-json/wc/v3/orders/{0}',
      customer: environment.KbwcUrl+'wp-json/wc/v3/customers/?per_page={0}&page={1}',
      customerID: environment.KbwcUrl+'wp-json/wc/v3/customers/{0}',
      getallorders: environment.KbwcUrl+'wp-json/wc/v3/orders/?per_page={0}&page={1}',
      customerByOrder: environment.KbwcUrl+'wp-json/wc/v3/orders?customer={0}',
      key: 'ck_ac010a2b562c986d5f341507e0c4e61b4df0d776',
      secret: 'cs_d469f13af70b70d9985de20cbf2a47586a061d0e',
      oauth_nonce: 'f8nRfVhtAh8',
      oauth_signature: 'eee/dTpR0X3mE8udhG60T5y2IiQ=',
      oauth_signature_method: 'HMAC-SHA1',
      oauth_timestamp: '1620466829',
      oauth_version: 1.0
    },
    local: {
      url: environment.KbwcUrl+'wp-json/wc/v3/orders',
      key: 'ck_a0be326c0346d459c1022cdcb14fd76d9dbd3fd6',
      secret: 'cs_2407c91ec6ff2a389b897e87d87be8f2a8c48b74',
      oauth_nonce: 'J9dgpFNQiZb',
      oauth_signature: 'FU2Qy2kmO5IqjpKE1ZHAOqmIuUA=',
      oauth_signature_method: 'HMAC-SHA1',
      oauth_timestamp: '1620381831',
      oauth_version: 1.0
    },
    node: {
      url: environment.baseurl,
      orderDetails: environment.baseurl+'user/insertTable',
      getstatus: environment.baseurl+'user/getstatus',
      productdisabled: environment.baseurl+'user/productdisabled'
    }
  };
  dataValue: any=[];
  permanantDataValue: any=[];
  bsRangeValue: any[];
  bsValue: any;

  constructor(private http: HttpClient) { 
    
  }

  public getDetails() {
    if (this.isDetails===true) {
      return this.customerdetails;
    } else if (this.isDetails===false){
      return this.customerdetails[0];
    }
  }


  public getWoo(qry: string, prm: string[]) {
    const aQry = this.formatQuery(qry, prm);
    return this.http.get(aQry, this.formatOauth(aQry));
  }

  

  public getImagePath(path: any) {
    return environment.imagesPath + path
  }

  public formatOauth(qry) {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Basic ${this.apiAuthentication}`,
      });
    return { headers };
  }

  //   public formatOauth(qry) {
  //     const consumer: any = {
  //       key: this.apiurl.kumari.key,
  //       secret: this.apiurl.kumari.secret
  //     }
  //     const hashFunction = (baseString, key) => {
  //       return CryptoJS.HmacSHA1(baseString, key).toString(CryptoJS.enc.Base64)
  //     }
  //     const oauth = new OAuth(
  //       {
  //         consumer: {
  //           key: consumer.key,
  //           secret: consumer.secret
  //         },
  //         signature_method: 'HMAC-SHA1',
  //         hash_function: hashFunction,
  //         nonce_length: 6,
  //         version: '1.0',
  //         realm: ''
  //       });
  //     const request = {
  //       url: qry,
  //       method: 'GET',
  //       params: {},
  //       headers: {}
  //     }
  //     const oauthObject = oauth.authorize(request);
  //     request.params = {
  //       oauth_consumer_key: 'ck_a0be326c0346d459c1022cdcb14fd76d9dbd3fd6',
  //       oauth_nonce: this.apiurl.kumari.oauth_nonce,
  //       oauth_signature: this.apiurl.kumari.oauth_signature,
  //       oauth_signature_method: this.apiurl.kumari.oauth_signature_method,
  //       oauth_version: this.apiurl.kumari.oauth_version,
  //       oauth_timestamp: this.apiurl.kumari.oauth_timestamp,
  //       oauth_token: ''
  //     }
  //     request.headers = oauth.toHeader(oauthObject)
  //     return { params: request.params, headers: request.headers }
  //   }

  public superEncodeURLComponent(url) {
    return encodeURIComponent(url).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  public formatQuery(gry: string, prm: any) {
    let theString = arguments[0];
    if (arguments.length !== 2) {
      return theString;
    }
    const theParams = arguments[1];
    const regexp = new RegExp('filter=');
    if (regexp.test(theString)) {
      theParams[0] = this.superEncodeURLComponent(theParams[0]);
    }
    for (let i = 0; i < theParams.length; i++) {
      if (theParams[i] !== undefined && theParams[i] !== null) {
        const regEx = new RegExp('\\{' + i + '\\}', 'gm');
        theString = theString.replace(regEx, theParams[i]);
      }
    }
    return theString;
  }

  

}
