
<div style="margin-top: 65px;margin-left: 665px;">
    <mat-form-field appearance="outline" *ngIf="deliveryPersonFilter===true && expensetabValue === false" >
        <input matInput [matDatepicker]="picker" [max]="maxDate" placeholder="Choose Date" (dateChange)="addEvent($event)" >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker panelClass="datepickerOne" ></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="deliveryPersonFilter===false && expensetabValue === false">
        <input matInput [matDatepicker]="picker"[max]="maxDate" placeholder="Choose Date" (dateChange)="addEvent($event)" [(ngModel)]="bsRangeValue" >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker panelClass="datepickerOne" ></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="deliveryPersonFilter===false && expensetabValue === true">
        <input matInput [matDatepicker]="picker"[max]="maxDate" placeholder="Choose Date" (dateChange)="addEvent($event)" [(ngModel)]="bsRangeValue" >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker panelClass="datepickerOne" ></mat-datepicker>
      </mat-form-field>
</div>
<div class="filt">
    <mat-form-field appearance="outline" *ngIf="deliveryPersonFilter===true && expensetabValue === false"  >
        <mat-label>Purchaser</mat-label>   
        <mat-select *ngIf="deliveryPersonFilter===true" (selectionChange)="onSelectPerson($event)">
            <mat-option value="All">All</mat-option>
            <mat-option   *ngFor="let name of purchseofficer" [value]="name.username"> {{name.username}}
            </mat-option>
        </mat-select>
    </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="deliveryPersonFilter===false && expensetabValue === false"  >
            <mat-label>Delivery Person</mat-label>
        <mat-select *ngIf="deliveryPersonFilter=== false" (selectionChange)="onSelectPerson($event)">
            <mat-option value="All">All</mat-option>
            <mat-option *ngFor="let name of deliverypersonsnames" [value]="name.username"> {{name.username}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="deliveryPersonFilter===false && expensetabValue === true"  >
        <mat-label>Select Name</mat-label>
    <mat-select *ngIf="deliveryPersonFilter=== false" (selectionChange)="onSelectPerson($event)">
        <mat-option value="All">All</mat-option>
        <mat-option *ngFor="let name of expTabUserData" [value]="name.username"> {{name.username}}
        </mat-option>
    </mat-select>
    </mat-form-field>
</div>  
<div class="header" style="margin-top:-60px;">
    
<mat-tab-group class="body" mat-align-tabs="start" (selectedTabChange)="tabchange($event)">
    <h3 class="heading">Estimation</h3>
    <mat-tab label="Purchaser"> 
       
        <table mat-table [dataSource]="dataSource" *ngIf="deliveryPersonFilter===true && this.expensetabValue === false;" matSort>

            <ng-container matColumnDef="date"name="date">
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Date </b> </th>
                <td mat-cell class="header_align" *matCellDef="let element"> {{element.expense_date}} </td>
            </ng-container>
    
            <ng-container matColumnDef="name" name="orderid">
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Name</b></th>
                <td mat-cell class="header_align" *matCellDef="let element">{{element.user_name}} </td>
            </ng-container>
            <ng-container matColumnDef="bill_no" name="bill_no">
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Bill No</b></th>
                <td mat-cell class="header_align" *matCellDef="let element"> {{element.bill_no}}</td>
            </ng-container>
            <ng-container matColumnDef="expense" name="expense">
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Expense</b></th>
                <td mat-cell class="header_align" *matCellDef="let element"> {{element.amount}}</td>
            </ng-container>

            <ng-container matColumnDef="description" name="description">
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Description</b></th>
                <td mat-cell class="header_align" *matCellDef="let element"
                style="max-width: 10px;overflow-wrap: break-word;padding: px;">{{element.description}}</td>
            </ng-container>
    
            <ng-container matColumnDef="status" name="priceamount">
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Status</b></th>
                <td mat-cell class="header_align" *matCellDef="let element">{{element.status}}</td>
            </ng-container>
           
    
            <ng-container matColumnDef="action" name="action">
                <th mat-header-cell  class="back_color" *matHeaderCellDef><b>Action</b> </th>
                <td mat-cell class="header_align" *matCellDef="let element;let index=index">
                    <!-- <span class="material-icons" style="margin-left: 10px;" (click)="view(element)">find_in_page</span>  -->
                    <mat-icon style="color: #04fb2c;cursor: pointer;" *ngIf="element.status==='Paid'">done_outline</mat-icon>
                    <mat-icon matTooltip="view page" [matTooltipPosition]="'above'" *ngIf="element.status==='Not-Paid'" (click)="openDialog(element)"
                    style="color: #f73508;cursor: pointer;">clear</mat-icon>
                    <mat-icon matTooltip="download" *ngIf="element.description != 'Purchased Products'" (click)="download(index)" [matTooltipPosition]="'above'"
                    style="color: rgba(0,0,0,.54);cursor: pointer;">
                    download</mat-icon>
                </td>
             </ng-container>
       
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            
        </table>
     
         </mat-tab>
    
    <mat-tab label="Delivery Person"> 
        <table mat-table [dataSource]="dataSource" *ngIf="deliveryPersonFilter=== false" matSort>

            <ng-container matColumnDef="date" name="date">
                <th mat-header-cell class="back_color"  *matHeaderCellDef><b>Date </b> </th>
                <td mat-cell class="header_align" *matCellDef="let element">{{element.expense_date}} </td>
            </ng-container>
    
            <ng-container matColumnDef="name" name="orderid" >
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Name</b></th>
                <td mat-cell class="header_align" *matCellDef="let element">{{element.user_name}} </td>
            </ng-container>
            <ng-container matColumnDef="expense" name="expense">
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Expense</b></th>
                <td mat-cell class="header_align" *matCellDef="let element"> {{element.amount}}</td>
            </ng-container>
            
            <ng-container matColumnDef="bill_no" name="bill_no" >
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Bill No</b></th>
                <td mat-cell class="header_align" *matCellDef="let element"> {{element.bill_no}}</td>
            </ng-container>

            <ng-container matColumnDef="description" name="description">
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Description</b></th>
              <td mat-cell class="header_align" style="max-width: 10px;overflow-wrap: break-word;padding: px;" *matCellDef="let element">
                    {{element.description}}
                </td>
            </ng-container>

            <ng-container matColumnDef="status" name="priceamount">
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Status</b></th>
                <td mat-cell class="header_align" *matCellDef="let element">{{element.status}}</td>
            </ng-container>

    
            <ng-container matColumnDef="action"  name="action">
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Action</b></th>
                <td mat-cell class="header_align" *matCellDef="let element;let index=index">
                    <!-- <span class="material-icons" style="margin-left: 10px;" (click)="view(element)">find_in_page</span>  -->
                    <mat-icon style="color: #04fb2c;cursor: pointer;" *ngIf="element.status === 'Paid'" >done_outline</mat-icon>
                    <mat-icon matTooltip="view page" [matTooltipPosition]="'above'" *ngIf="element.status==='Not-Paid'" (click)="openDialog(element)"
                    style="color: #f73508;cursor: pointer;">clear</mat-icon>
                    <mat-icon matTooltip="download" *ngIf="element.description != 'Delivered Payment'" (click)="download(index)" [matTooltipPosition]="'above'"
                    style="color: rgba(0,0,0,.54);cursor: pointer;">
                    download</mat-icon>
                </td>
             </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            
        </table>
       

    </mat-tab>
    <mat-tab label="Expense"> 
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="date" name="date">
                <th mat-header-cell class="back_color"  *matHeaderCellDef><b>Date </b> </th>
                <td mat-cell class="header_align" *matCellDef="let element">{{element.expense_date}} </td>
            </ng-container>
    
            <ng-container matColumnDef="name" name="orderid" >
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Name</b></th>
                <td mat-cell class="header_align" *matCellDef="let element">{{element.user_name}} </td>
            </ng-container>
            <ng-container matColumnDef="expense" name="expense">
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Expense</b></th>
                <td mat-cell class="header_align" *matCellDef="let element"> {{element.amount}}</td>
            </ng-container>
            
            <ng-container matColumnDef="bill_no" name="bill_no" >
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Bill No</b></th>
                <td mat-cell class="header_align" *matCellDef="let element"> {{element.bill_no}}</td>
            </ng-container>

            <ng-container matColumnDef="description" name="description">
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Description</b></th>
              <td mat-cell class="header_align" style="max-width: 10px;overflow-wrap: break-word;padding: px;" *matCellDef="let element">
                    {{element.description}}
                </td>
            </ng-container>

            <ng-container matColumnDef="status" name="priceamount">
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Status</b></th>
                <td mat-cell class="header_align" *matCellDef="let element">{{element.status}}</td>
            </ng-container>

    
            <ng-container matColumnDef="action"  name="action">
                <th mat-header-cell class="back_color" *matHeaderCellDef><b>Action</b></th>
                <td mat-cell class="header_align" *matCellDef="let element;let index=index">
                    <!-- <span class="material-icons" style="margin-left: 10px;" (click)="view(element)">find_in_page</span>  -->
                    <mat-icon style="color: #04fb2c;cursor: pointer;" *ngIf="element.status === 'Paid'" >done_outline</mat-icon>
                    <mat-icon matTooltip="view page" [matTooltipPosition]="'above'" *ngIf="element.status==='Not-Paid'" (click)="openDialog(element)"
                    style="color: #f73508;cursor: pointer;">clear</mat-icon>
                    <mat-icon matTooltip="download" *ngIf="element.description != 'Delivered Payment'" (click)="download(index)" [matTooltipPosition]="'above'"
                    style="color: rgba(0,0,0,.54);cursor: pointer;">
                    download</mat-icon>
                </td>
             </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            
        </table>
       

    </mat-tab>
  </mat-tab-group>
        <mat-paginator #paginator class="paginator" *ngIf="deliveryPersonFilter===true && expensetabValue === false" [pageSize]="10" 
             [pageSizeOptions]="[10, 20, 50,100]" 
            >
             </mat-paginator>
             <mat-paginator #paginator class="paginator" *ngIf="deliveryPersonFilter=== false && expensetabValue === false" [pageSize]="10" 
             [pageSizeOptions]="[10, 20, 50,100]" 
             >
             </mat-paginator>
             <mat-paginator #paginator class="paginator" *ngIf="deliveryPersonFilter=== false && expensetabValue === true" [pageSize]="10" 
             [pageSizeOptions]="[10, 20, 50,100]" 
             >
             </mat-paginator>
             
</div>
