import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { element } from 'protractor';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';
import { DialogboxComponent } from '../dialogbox/dialogbox.component';
import { DatePipe } from '@angular/common';
import { filter, forEach } from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { LoginComponent } from '../login/login.component';
import { MatSort } from '@angular/material/sort';
import * as saveAs from 'file-saver';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-costestimation',
  templateUrl: './costestimation.component.html',
  styleUrls: ['./costestimation.component.scss']
})
export class CostestimationComponent implements OnInit {
  displayedColumns: string[] = ['date', 'name', 'bill_no', 'expense', 'description', 'status', 'action'];
  // public dataValue: any = [];
  public userprofile: any = [];
  // public deliveryPersonArray: any = [];
  public permanantDataValue: any = [];
  public deliverypersonsnames: any = [];
  public bsRangeValue: Date[];
  public bsValue = new Date();
  public purchseofficer: any = [];
  public maxDate = new Date();
  public currentDate = new Date().toISOString().slice(0, 10)
  public dataSource = new MatTableDataSource(this.permanantDataValue);
  public matDialogRef: MatDialogRef<DialogboxComponent>;
  public selection = new SelectionModel(true, []);
  public img: any;
  public photo: any;
  public deliveryPersonFilter: boolean = true;
  public expensetabValue: boolean = false;
  public expTabUserData: any = [];
  public ticiconPurchaser: boolean = false;
  public ticiconDeliveryPerson: boolean = false;
  public newArray: any = [];
  public data: any = [];
  public expensedata: any = [];
  // public setValue: any = [];
  // public maindataValue: any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(public http: HttpClient, public dialog: MatDialog, public appService: AppService, public router: Router,
    private datepipe: DatePipe) {
    // this.appService.show.next(true);
    this.maxDate.setDate(this.maxDate.getDate())
  }
  filterValues = {
    name: '',
  }
  filterForm = new FormGroup({
    name: new FormControl()
  });
  get name() { return this.filterForm.get('name') }

  ngOnInit(): void {
    this.http.get(environment.baseurl + 'user/dropDownValues').subscribe((x: any) => {
      this.expTabUserData=x //dropdown filter
      this.deliverypersonsnames = x.filter(a => a.usertype == 'DeliveryPerson')
      this.purchseofficer = x.filter(b => b.usertype == 'PurchaseOfficer')
    })
    if (this.deliveryPersonFilter === true) {
      this.purchasertab();
    } else if (this.deliveryPersonFilter === false) {
      this.deliveryPersontab()
    }
  }

  public purchasertab() {
    this.http.get(environment.baseurl + 'user/payment').subscribe((X: any) => {
      // this.permanantDataValue = X;
      this.permanantDataValue = X.filter((x) => (x.description === 'Purchased Products'));
      this.dataSource = this.permanantDataValue
      this.dataSource = new MatTableDataSource(this.permanantDataValue);
      this.dataSource.paginator = this.paginator;
    })
  }

  public expensetab() {
    this.http.get(environment.baseurl + 'user/expensepayment').subscribe((X: any) => {
      this.expensedata =X.filter((x) => (x.description != 'Purchased Products' && x.description != 'Delivered Payment'));
     this.dataSource = this.expensedata;
     this.dataSource = new MatTableDataSource(this.expensedata);
     this.dataSource.paginator = this.paginator;
    })
  }

  public deliveryPersontab() {
    let payload: any = {
      usertype: 'DeliveryPerson',
      currentdate: this.currentDate
    }
    this.http.post(environment.baseurl + 'user/paymentsDeliveryBoy', payload).subscribe((X: any) => {
      this.newArray = X;
      this.dataSource = this.newArray
      this.dataSource = new MatTableDataSource(this.newArray);
      this.dataSource.paginator = this.paginator;

    })
  }
  tabchange(v) {
    if (v.index == 0) {
      this.purchasertab()
      this.deliveryPersonFilter = true;
      this.expensetabValue = false;

    } else if (v.index == 1) {
      this.deliveryPersontab();
      this.deliveryPersonFilter = false;
      this.expensetabValue = false;
    }else if (v.index == 2) {
      this.expensetab();
      this.expensetabValue = true;
      this.deliveryPersonFilter = false;
    }
  }

  addEvent(date: MatDatepickerInputEvent<Date>) {
    if (this.deliveryPersonFilter === true && this.expensetabValue === false) {
      if (date.value != null) {
        let selected_date = this.datepipe.transform(date.value, 'yyyy-MM-dd')
        const payLoad = {
          date: selected_date,
          usertype: 'PurchaseOfficer',
          description: 'Purchased Products'
        }
        this.http.post(environment.baseurl + 'user/dateFilter', payLoad).subscribe((x: any) => {
          this.dataSource = x;
          this.dataSource.paginator = this.paginator;
        })
      } else if (date.value === null) {
        this.dataSource = this.permanantDataValue;
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.deliveryPersonFilter === false && this.expensetabValue === false) {
      if (date.value != null) {
        let dateFilter = this.datepipe.transform(date.value, 'yyyy-MM-dd')
        const payLoad = {
          date: dateFilter,
          usertype: 'DeliveryPerson',
          description: 'Delivered Payment'
        }
        this.http.post(environment.baseurl + 'user/dateFilter', payLoad).subscribe((x: any) => {
          this.dataSource = x;
          this.dataSource.paginator = this.paginator;
        })
      } else if (date.value === null) {
        this.dataSource = this.newArray
        this.dataSource.paginator = this.paginator;
      }
    }else if (this.deliveryPersonFilter === false && this.expensetabValue === true) {
      if (date.value != null) {
        let dateFilter = this.datepipe.transform(date.value, 'yyyy-MM-dd')
        const payLoad = {
          date: dateFilter, 
        }
        this.http.post(environment.baseurl + 'user/expensedateFilter', payLoad).subscribe((x: any) => {
          this.dataSource = x.filter((x) => (x.description != 'Purchased Products' && x.description != 'Delivered Payment')); 
          this.dataSource.paginator = this.paginator;
        })
      } else if (date.value === null) {
        this.dataSource = this.newArray
        this.dataSource.paginator = this.paginator;
      }
    }
  }
  openDialog(data: any) {
    this.appService.matboxid = data;
    this.matDialogRef = this.dialog.open(DialogboxComponent, {
      data
    });
    if (this.deliveryPersonFilter === true && this.expensetabValue === false) {
      this.matDialogRef.afterClosed().subscribe(() => {
        this.purchasertab();
      });
    } else if (this.deliveryPersonFilter === false && this.expensetabValue === false) {
      this.matDialogRef.afterClosed().subscribe(() => {
        this.deliveryPersontab()
      });
    }else if(this.expensetabValue === true && this.deliveryPersonFilter === false) {
      this.matDialogRef.afterClosed().subscribe(() => {
        this.expensetab()
      });
    }
  }

  onSelectPerson(eve: any) {
    if (this.deliveryPersonFilter === true && this.expensetabValue === false) {
      this.permanantDataValue.forEach(element => {
        if (element.user_name === eve.value) {
          this.dataSource = this.permanantDataValue.filter(x => x.user_name == eve.value);
          // this.dataSource.paginator = this.paginator;
        } else if (eve.value == "All" && this.deliveryPersonFilter === true) {
          this.dataSource = this.permanantDataValue;
          // this.dataSource.paginator = this.paginator;
        }
        // else if (element.user_name != eve.value) {
        //   this.dataSource = this.data
        //   this.dataSource.paginator = this.paginator;

        // }
      });
    } else if (this.deliveryPersonFilter === false && this.expensetabValue === false) {
      this.newArray.forEach((ele) => {
        if (ele.user_name === eve.value) {
          this.dataSource = this.newArray.filter(x => x.user_name == eve.value);
          this.dataSource.paginator = this.paginator;
        } else if (eve.value == "All") {
          this.dataSource = this.newArray.filter(x => x.user_type == 'DeliveryPerson');
          this.dataSource.paginator = this.paginator;
        }
        // else if (eve.value != eve.value) {
        //   this.dataSource = this.data
        //   this.dataSource.paginator = this.paginator;
        // }
      })
    }else if(this.expensetabValue === true && this.deliveryPersonFilter === false){
      this.expensedata.forEach((ele) => {
        if (ele.user_name === eve.value) {
          this.dataSource = this.expensedata.filter(x => x.user_name == eve.value);
          this.dataSource.paginator = this.paginator;
        } else if (eve.value == "All") {
          this.dataSource = this.expensedata.filter(x => x.user_type == 'DeliveryPerson');
          this.dataSource.paginator = this.paginator;
        }
      })
    }
  }
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getRandomInt() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  public download(index) {
    if (this.deliveryPersonFilter === true && this.expensetabValue === false) {
      const file = {
        bill: this.permanantDataValue[index].bill_no,
        description: this.permanantDataValue[index].description
      }
      this.http.post(environment.baseurl + 'user/getBill', file).subscribe(async (x: any) => {
        this.userprofile = x;
        this.userprofile.forEach(async x => {
          this.photo = x ? this.appService.getImagePath(x) : '';
          const base64Response = await fetch(this.photo);
          const blob = await base64Response.blob();
          saveAs(blob, this.getRandomInt() + '.png')
        });
      });
    } else if (this.deliveryPersonFilter === false && this.expensetabValue === false) {
      const file = {
        bill: this.newArray[index].bill_no,
        description: this.newArray[index].description
      }
      this.http.post(environment.baseurl + 'user/getBill', file).subscribe(async (x: any) => {
        this.userprofile = x;
        this.userprofile.forEach(async x => {
          this.photo = x ? this.appService.getImagePath(x) : '';
          const base64Response = await fetch(this.photo);
          const blob = await base64Response.blob();
          saveAs(blob, this.getRandomInt() + '.png')
        });
      });
    }else if(this.expensetabValue === true && this.deliveryPersonFilter === false){
      const file = {
        bill: this.expensedata[index].bill_no,
        description: this.expensedata[index].description
      }
      this.http.post(environment.baseurl + 'user/getBill', file).subscribe(async (x: any) => {
        this.userprofile = x;
        this.userprofile.forEach(async x => {
          this.photo = x ? this.appService.getImagePath(x) : '';
          const base64Response = await fetch(this.photo);
          const blob = await base64Response.blob();
          saveAs(blob, this.getRandomInt() + '.png')
        });
      });
    }


  }

}


