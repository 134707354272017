<div class="model-dialog">
    <div class="row">
        <div class="col-6">
            <h2 style="margin-left: 5%;">Purchased Products </h2>
        </div>
    <div class="col-6">
        <span style="cursor: pointer; float: right;
        color: rgba(0,0,0,.54);" ><mat-icon  (click)="back()" matTooltip="close" [matTooltipPosition]="'right'">close</mat-icon></span>
  </div>
</div>

<div class="mat-elevation-z8"  style="margin-left: 17px;">      
    <table mat-table [dataSource]="dataValue" >
        <ng-container matColumnDef="bill_no" name="billing_no" ngDefaultControl >
            <th mat-header-cell class="back_color" *matHeaderCellDef> Bill No </th>
            <td mat-cell class="header_align" *matCellDef="let element"> {{element.billing_no}} </td>
        </ng-container>

        <ng-container matColumnDef="productname" name="productname" ngDefaultControl >
            <th mat-header-cell class="back_color" *matHeaderCellDef> Product Name </th>
            <td mat-cell class="header_align" *matCellDef="let element"> {{element.productname}} </td>
        </ng-container>

         <ng-container matColumnDef="purchasedprice" name="purchasedprice" ngDefaultControl >
         <th mat-header-cell  class="back_color" *matHeaderCellDef> Purchased Price </th>
         <td mat-cell *matCellDef="let element">
                 <input type="number " class="price" mat-cell name="purchasedprice" placeholder="Enter price" [(ngModel)]="element.purchase_price" required >
            </td>
        </ng-container>

        <ng-container matColumnDef="quantity" name="quantity" ngDefaultControl >
            <th mat-header-cell class="back_color1" *matHeaderCellDef> Quantity   </th>
            <td mat-cell class="name" *matCellDef="let element"> {{element.quantity}} </td><br><br>
        </ng-container>

        <ng-container matColumnDef="selling price">
            <th mat-header-cell class="back_color" *matHeaderCellDef ngDefaultControl > Selling Price</th>
            <td mat-cell class="header_align" *matCellDef="let element"> {{element.price}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
        
    </table>

</div>
</div>
<div mat-dialog-actions class="button-group">
    <button mat-raised-button color="warn" (click)="back()"  *ngIf="service.isHide">Back</button>
    <button mat-raised-button class="btn_PDF" (click)="editPurchasePrice(dataValue)"  mat-dialog-close *ngIf="service.isHide">Update</button>

</div>
